import React, { useState } from 'react'
import css from './SubmitCaseStudyForm.module.css'
import { FormattedMessage, injectIntl } from 'react-intl';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';
import {Field, Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, IconCard, ExternalLink } from '../../components';
import { compose } from 'redux';
import { ACCEPT_FILE } from '../../util/types';
import { handleFileUpload } from '../../util/api';
import { requestCaseStudy } from '../../containers/TransactionPage/TransactionPage.duck';
import { TRANSITION_SUBMIT_CASE_STUDY } from '../../util/transaction';
import moment from 'moment';

const validFileFormat = ['pdf','doc','docx','ppt','pptx'];

function SubmitCaseStudyForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          ready,
          handleSubmit,
          pristine,
          submitProgress,
          form,
          caseStudyDetails, 
          caseStudyDeadline, 
          deadlineDueDays,
          setOpenModal,
          isCaseStudySubmitted,
          submission,
          intl,
          values,
          dispatch,
          transaction
        } = formRenderProps;
        
        const [isloading, setIsLoading] = useState(false);
        const [error, setError]= useState('');
        const classes = classNames(css.root, className);
        const submitDisabled = !values?.file?.name;

        const title = intl.formatMessage({ id: 'SubmitCaseStudyForm.title' });
        const caseStudyLabel = intl.formatMessage({ id: 'SubmitCaseStudyForm.caseStudyLabel' });
        const downlaodCaseStudyLabel = intl.formatMessage({ id: 'SubmitCaseStudyForm.downlaodCaseStudyLabel' });
        const deadlineLabel = intl.formatMessage({ id: 'SubmitCaseStudyForm.deadlineLabel' });
        const timeRemainingLabel = intl.formatMessage({ id: 'SubmitCaseStudyForm.timeRemainingLabel' });
        const submissionLabel = intl.formatMessage({ id: 'SubmitCaseStudyForm.submissionLabel' });
        const downloadSubmissionLabel = intl.formatMessage({ id: 'SubmitCaseStudyForm.downloadSubmissionLabel' });
        const submitCaseStudy = intl.formatMessage({ id: 'SubmitCaseStudyForm.submitCaseStudy' });

        function handleError(errorMessage){
          setError(errorMessage)
          setTimeout(() => setError(''), 3000)
        }

        async function handleSubmitCaseStudy(values) {
          setIsLoading(true)
          const { file } = values || {};
          const response = await handleFileUpload('upload-file-aws', {file})
          const { location,key } = response?.data || {};
      
          if(location){
            const attachment = {
              link: location,
              date: moment().format('MM-DD-YYYY hh:mm:ss'),
              name: file.name,
              size: file.size,
              key
            }
        
            const param = { submission: attachment }
            dispatch(requestCaseStudy({ transaction, param, isTransition: true, transition: TRANSITION_SUBMIT_CASE_STUDY }))
              .then(response => {
                if (response?.data?.data?.id) setOpenModal('')
              })
          }
          else {
            form.change('file', {})
            handleError('Unexpected error occured uploading file. Try file with different name or try after some time.')
          }
          setIsLoading(false)
        }

        return(
          <Form className={classes} onSubmit={e => {
            e.preventDefault()
            handleSubmitCaseStudy(values)
          }}>
            <h2 className={css.title}>{title}</h2>
            <div className={css.row}>
              <label>{caseStudyLabel}</label>
              <ExternalLink href={caseStudyDetails?.attachment?.link}>{downlaodCaseStudyLabel}</ExternalLink>
            </div>
            <div className={css.row}>
              <label>{deadlineLabel}</label>
              <span>{caseStudyDeadline}</span>
            </div>
            <div className={css.row}>
              <label>{timeRemainingLabel}</label>
              <span>{deadlineDueDays} days</span>
            </div>
            {isCaseStudySubmitted ? (
              <div className={css.row}>
                <label>{submissionLabel}</label>
                <ExternalLink href={submission?.link}>{downloadSubmissionLabel}</ExternalLink>
              </div>
            ) : (
              <>
                <div className={css.attachmentLabel}>{submitCaseStudy}</div>
                {!values?.file?.name? (
                  <div className={css.inputSection}>
                    <Field
                      id="caseStudySubmission"
                      name="caseStudySubmission"
                      accept={ACCEPT_FILE}
                      type="file"
                    >
                    {fieldprops => {
                      const { accept, input} = fieldprops;
                      const { name, type } = input;
                      const onChange = async (e) => {
                        const file = e.target.files[0];
                        const extension = file?.name?.split('.').at(-1);

                        if(!validFileFormat.includes(extension)){
                          handleError('Only PDF, DOC or PPT formats accepted.')
                          return
                        }
                        form.change('file', file)
                      };
                      const inputProps = { accept, id: name, name, onChange, type };
                      return <input {...inputProps} className={css.addImageInput} />
                    }}
                    </Field>
                    <label htmlFor="caseStudySubmission" className={css.inputLabel}>
                      <IconCard brand="upload" />
                      <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                      <span className={css.docType}>
                        <FormattedMessage id="JobListing.documentAllowList" />
                      </span>
                    </label>
                    {error && <p className={css.errorMessage}>{error}</p>}
                  </div>
                ) : (
                  <FileView file={values?.file}>
                    <span className={css.removeFile} onClick={() => form.change('file', {})}>
                      <FormattedMessage id="ZohoSignForm.remove"/>
                    </span>
                  </FileView>
                )}
                <div className={css.buttonWrapper}>
                  <span className={css.buttonCancel} onClick={() => setOpenModal('')}>
                  <FormattedMessage id =  "SubmitCaseStudyForm.cancelButton" />
                  </span>
                  <PrimaryButton
                    className={css.buttonSubmit}
                    type='sumbit'
                    disabled={submitDisabled}
                    inProgress={submitProgress || isloading}
                  >
                  <FormattedMessage id =  "SubmitCaseStudyForm.submitButton" />
                  </PrimaryButton>
                </div>
              </>
            )}
          </Form>
        )
      }}
    />
  )
}

export default compose(injectIntl)(SubmitCaseStudyForm)