import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCEPT_FILE, FULLTIME_KEY, SECONDMENT_KEY, propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, LocationAutocompleteInputField, FieldCheckbox, IconCard, FieldRadioButton, IconSpinner } from '../../components';
import * as validators from '../../util/validators';
import css from './EditListingJobRoleForm.module.css';
import moment from 'moment';
import FileView from '../../components/FileView/FileView';
import { handleFileUpload, removeFileFromAzure } from '../../util/api';
import axios from 'axios';

const TITLE_MAX_LENGTH = 60;
const seniorityOptions = [
  {key: 'junior', label: 'Junior'},
  {key: 'middle', label: 'Middle'},
  {key: 'senior', label: 'Senior'},
  {key: 'cxo', label: 'CXO'},
]

const EditListingJobRoleForm = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        attachment
      } = formRenderProps;
      
      const [file, setFile] = useState(attachment);
      //Job Role or Designation
      const roleLabel = intl.formatMessage({ id: 'EditListingJobRoleForm.roleLabel' });
      const rolePlaceholderMessage = intl.formatMessage({ id: 'EditListingJobRoleForm.rolePlaceholderMessage'});
      const roleRequiredMessage = intl.formatMessage({ id: 'EditListingJobRoleForm.roleRequiredMessage'});
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingBriefDescriptionForm.maxLength' },
        { maxLength: TITLE_MAX_LENGTH }
      );

      //Job Role or Designation Description
      const jobDescriptionLabel = intl.formatMessage({ id: 'EditListingJobRoleForm.jobDescriptionLabel'});
      const jobDescriptionPlaceholderMessage = intl.formatMessage({id: 'EditListingJobRoleForm.jobDescriptionPlaceholderMessage',});
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({ id: 'EditListingJobRoleForm.descriptionRequired'});

      const seniorityLabel = intl.formatMessage({ id: 'EditListingJobRoleForm.seniorityLabel'});
      const locationPlaceHolder = intl.formatMessage({ id: 'EditListingJobRoleForm.locationPlaceHolder'});
      const checkboxLabel = intl.formatMessage({ id: 'EditListingJobRoleForm.checkboxLabel'})
      const employmentLengthLabel = intl.formatMessage({ id: 'EditListingJobRoleForm.employmentLengthLabel'})

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBriefDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBriefDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingBriefDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const isRemoteLocation = !!values.remoteLocation?.length && values.remoteLocation[0] === 'true';
      const employementRequired = values.employementRequired?.length;
      const submitDisabled = invalid || disabled || submitInProgress || (!isRemoteLocation && !values.location?.search)
        || (!employementRequired && !values.employmentLength) || !values.contractType;

      const [isLoading, setIsLoading] = useState(false);
      const [error, setError] = useState('');

      function handleError(errorMessage){
        setError(errorMessage)
        setTimeout(() => setError(''), 5000)
      }

      async function handleDocumentUpload(file){
        setIsLoading(true)
        if(file && file.name){
          const response = await handleFileUpload('upload-file-aws', {file})
          
          if(response?.data?.status !== 200){
            handleError('Unexpected error occured uploading file. Try file with different name or try after some time.')
            setIsLoading(false)
            return
          }

          const { location,key } = response?.data || {};
          const attachment = {
            link: location,
            date: moment().format('MM-DD-YYYY hh:mm:ss'),
            name: file.name,
            size: file.size,
            key
          }
          if(location){
            setFile(file)
            form.change('attachmentFile', attachment)
            setIsLoading(false)
          }
        }
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="input"
            label={roleLabel}
            placeholder={rolePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(roleRequiredMessage), maxLength60Message)}
          />
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={jobDescriptionLabel}
            placeholder={jobDescriptionPlaceholderMessage}
            validate={required(descriptionRequiredMessage)}
          />
          <FieldSelect
            className={className}
            name="seniority"
            id="seniority"
            label={seniorityLabel}
          >
            <option value="">Select Level...</option>
            {seniorityOptions.map(({key, label}) => (
              <option key={key} value={key}>{label}</option>
            ))}
          </FieldSelect>
          <LocationAutocompleteInputField
            rootClassName={classNames(css.location, isRemoteLocation && css.disabled)}
            name="location"
            label={"Location"}
            placeholder={locationPlaceHolder}
            useDefaultPredictions={false}
            format={v => v}
            valueFromForm={values.location ? values.location: {}}
            disabled={isRemoteLocation}
          />   
          <FieldCheckbox
            className={css.checkbox} 
            id='remoteLocation'
            name='remoteLocation'
            label={checkboxLabel} 
            value={"true"} 
          />
          <div className={css.attachmentLabel}><FormattedMessage id="EditListingJobRoleForm.contractTypeLabel" /></div>
          <div className={css.contractType}>
            <FieldRadioButton 
              id="fulltime"
              name="contractType"
              label="Employed"
              value={FULLTIME_KEY}
              onChange={e => {
                form.change('contractType', e.target.value)
                form.change('employementRequired', ['true'])
              }}
            />
            <FieldRadioButton 
              id="secondment"
              name="contractType"
              label="Secondment"
              value={SECONDMENT_KEY}
              onChange={e => {
                form.change('contractType', e.target.value)
                form.change('employementRequired', [])
              }}
            />
          </div>
          <div className={classNames(css.employment, employementRequired && css.disabled)}>
            <FieldTextInput
              id="employmentLength"
              name="employmentLength"
              type="number"
              label={employmentLengthLabel}
              placeholder='0'
              disabled={employementRequired}
            />
            <span>months</span>
          </div>
          <FieldCheckbox
            className={css.checkbox} 
            id='employementRequired'
            name='employementRequired'
            label='N/A' 
            value={"true"} 
          />
          <div className={css.attachmentLabel}><FormattedMessage id="EditListingJobRoleForm.attachmentLabel" /></div>
          {!file?.name ? (
            <div className={css.inputSection}>
              <Field
                id="attachmentFile"
                name="attachmentFile"
                accept={ACCEPT_FILE}
                type="file"
              >
                {fieldprops => {
                  const { accept, input} = fieldprops;
                  const { name, type } = input;
                  const onChange = async (e) => {
                    handleDocumentUpload(e.target.files[0])
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return <input {...inputProps} className={css.addImageInput} />
                }}
              </Field>
              <label htmlFor="attachmentFile" className={css.inputLabel}>
                {isLoading ? (
                  <IconSpinner strokeColor='orange'/>
                ) : (
                  <>
                    <IconCard brand="upload" />
                    <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                    <span className={css.docType}>
                      <FormattedMessage id="JobListing.documentAllowList" />
                    </span>
                  </>
                )}
              </label>
              {error && <p className={css.error}>{error}</p>}
            </div>
            ) : (
              <FileView file={file}>
                <span className={css.removeFile} onClick={async () => {
                  await removeFileFromAzure({ key: file.name })
                  setFile({})
                  form.change('attachmentFile', {})
                }}>
                  <FormattedMessage id="ZohoSignForm.remove"/>
                </span>
              </FileView>
            )}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingJobRoleForm.defaultProps = { className: null, fetchErrors: null };

EditListingJobRoleForm.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  })
};

export default compose(injectIntl)(EditListingJobRoleForm);
