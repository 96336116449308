import React, { useState } from 'react'
import css from './TransactionPanel.module.css'
import { FormattedMessage } from 'react-intl';
import IconCard from '../IconCard/IconCard';
import { TRANSITION_ACCEPT_JOB_APPLICATION, TRANSITION_ACCEPT_JOB_APPLICATION_AFTER_JOB, TRANSITION_ACCEPT_JOB_OFFER, TRANSITION_COMFIRM_CONTRACT_SIGNATURE, TRANSITION_CONFIRM_FIRST_INTERVIEW, TRANSITION_CONFIRM_OFFLINE_PAYMENT, TRANSITION_CONFIRM_SECOND_INTERVIEW, TRANSITION_DECLINE_APPLICATION_AFTER_JOB, TRANSITION_DECLINE_CASE_STUDY, TRANSITION_DECLINE_FIRST_INTERVIEW, TRANSITION_DECLINE_JOB_APPLICATION, TRANSITION_DECLINE_JOB_DESCRIPTION, TRANSITION_DECLINE_JOB_OFFER, TRANSITION_DECLINE_SECOND_INTERVIEW, TRANSITION_REJECT_JOB_AFTER_APPLICATION, TRANSITION_REJECT_JOB_INVITE, TRANSITION_REQUEST_CASE_STUDY, TRANSITION_REQUEST_CONTRACT_SIGNATURE, TRANSITION_REQUEST_FIRST_INTERVIEW, TRANSITION_REQUEST_OFFLINE_PAYMENT, TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT, TRANSITION_REQUEST_SECOND_INTERVIEW, TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_PROVIDER, TRANSITION_SEND_JOB_APPLICATION, TRANSITION_SEND_JOB_DESCRIPTION, TRANSITION_SEND_JOB_OFFER, TRANSITION_SUBMIT_CASE_STUDY, txIsBothReviewGiven, txIsConfirmFirstInteview, txIsConfirmOfflinePayment, txIsConfirmSecondInteview, txIsContractSend, txIsContractSigned, txIsDeclinedCaseStudy, txIsDeclinedInteview, txIsJobApplicationDeclined, txIsJobApplicationExpire, txIsJobApplicationSent, txIsJobDescriptionDeclined, txIsJobDescriptionExpire, txIsJobDescriptionSent, txIsJobOfferAccepted, txIsJobOfferDeclined, txIsJobOfferSend, txIsOneReviewGiven, txIsRejectedJob, txIsRequestCaseStudy, txIsRequestFirstInteview, txIsRequestSecondInteview, txIsRequestedOfflinePayment, txIsReviewed, txIsSubmittedCaseStudy } from '../../util/transaction';
import { Menu, MenuLabel, MenuContent, MenuItem, Modal, PrimaryButton, ModalApplication, LoadingDots, NotificationBar, OutsideClickHandler, IconSpinner, } from '../../components';
import classNames from 'classnames';
import MenuIcon from '../ManageListingCard/MenuIcon';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { declineBrief, declineProposal, handleJobTransition, requestCaseStudy, sendProposal, zohoAccessToken } from '../../containers/TransactionPage/TransactionPage.duck';
import { useDispatch } from 'react-redux';
import RejectApplicationForm from '../../forms/RejectApplicationForm/RejectApplicationForm';
import moment from 'moment';
import CaseStudyForm from '../../forms/CaseStudyForm/CaseStudyForm';
import SubmitCaseStudyForm from '../../forms/SubmitCaseStudyForm/SubmitCaseStudyForm';
import { BOTH_EXPERT_AND_INSIGHTGIG, FULLTIME_KEY, FULL_TIME_EMPLOYMENT, OFFLINE, ONLINE, ONLY_INSIGHTGIG, PRIVATELY_SHARED_JOB_APPLICATIONS, RECRUITMENTS_UNDERWAY, SECONDMENT, TRANSACTION_APPLY_JOB_PROCESS } from '../../util/types';
import SendOfferForm from '../../forms/SendOfferForm/SendOfferForm';
import ViewJobOfferModal from '../ViewJobOfferModal/ViewJobOfferModal';
import SignContractForm from '../../forms/SignContractForm/SignContractForm';
import { getUserDetails } from '../../util/destructorHelpers';
import JobPaymentForm from '../../forms/JobPaymentForm/JobPaymentForm';
import ViewApplicationModal from '../ViewApplicationModal/ViewApplicationModal';
import FileView from '../FileView/FileView';
import { deleteRecruitment, handleFileUpload, sendEmailUsingZepto, updateFirmUserDetails } from '../../util/api';

const { Money } = sdkTypes;
const emptySpace = '-';
//Modal Names
const APPLICATION_MODAL = 'applicationModal';
const APPLICATION_FORM = 'applicationForm';
const REJECT_MODAL = 'rejectModal';
const CASE_STUDY_MODAL = 'caseStudyModal';
const SUBMIT_CASE_MODAL = 'submitCaseModal';
const SEND_OFFER_MODAL = 'sendOfferModal';
const VIEW_OFFER_MODAL = 'viewOfferModal';
const CONTRACT_MODAL = 'contractModal';
const PAYMENT_MODAL = 'paymentModal';

//Skips Steps
const STEP_FIRST_INTERVIEW = 'Step 1: Invite to interview';
const STEP_SEND_CASE_STUDY = 'Step 3: Send case study';
const STEP_SECOND_INTERVIEW = 'Step 4: Invite to second interview';

const customerTranstions = [
  TRANSITION_SEND_JOB_APPLICATION,
  TRANSITION_ACCEPT_JOB_APPLICATION,
  TRANSITION_ACCEPT_JOB_APPLICATION_AFTER_JOB,
  TRANSITION_SUBMIT_CASE_STUDY,
  TRANSITION_ACCEPT_JOB_OFFER,
  TRANSITION_CONFIRM_FIRST_INTERVIEW,
  TRANSITION_CONFIRM_SECOND_INTERVIEW,
  TRANSITION_REQUEST_FIRST_INTERVIEW,
  TRANSITION_REQUEST_SECOND_INTERVIEW,
  TRANSITION_REQUEST_CONTRACT_SIGNATURE,
  TRANSITION_COMFIRM_CONTRACT_SIGNATURE,
]
const expertTranstions = [
  TRANSITION_SEND_JOB_DESCRIPTION,
  TRANSITION_REQUEST_FIRST_INTERVIEW,
  TRANSITION_REQUEST_SECOND_INTERVIEW,
  TRANSITION_REQUEST_CASE_STUDY,
  TRANSITION_SEND_JOB_OFFER,
]

function stateDataFn(tx, isClient, customerName, expertName, meetingDate, isOfflineContract) {
  const processName = tx?.id && tx?.attributes?.processName;
  const isChainedTransaction = processName === TRANSACTION_APPLY_JOB_PROCESS;
  const { firstInterviewData, reason: rejectionReason, submission, } = tx?.id ? tx?.attributes?.metadata : {};
  const interviewDeclinedReason = firstInterviewData?.declineReason;
  const caseStudyDeclinedReason = submission?.declineReason;

  if (txIsJobDescriptionSent(tx)) {
    return isClient
      ? {
        icon: 'briefrecieved',
        id: 'JobDescription.sentJobDescription',
        userName: expertName,
      }
      : {
        icon: 'response',
        id: 'JobDescription.receivedJobDescription',
        userName: customerName,
      };
  } else if (txIsJobDescriptionDeclined(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.jobDescDeclinedClient',
        userName: expertName,
      }
      : {
        icon: 'declined',
        id: 'JobDescription.jobDescDeclinedExpert',
        userName: customerName,
      };
  } else if (txIsJobApplicationSent(tx)) {
    return isClient
      ? {
        icon: 'briefrecieved',
        id: 'JobDescription.receivedJobApplication',
        userName: expertName,
      }
      : {
        icon: 'response',
        id: 'JobDescription.sentJobApplication',
        userName: isChainedTransaction ? expertName : customerName,
      };
  } else if (txIsJobApplicationExpire(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.jobApplicationExpiredClient',
        userName: expertName,
      }
      : {
        icon: 'declined',
        id: 'JobDescription.jobApplicationExpiredExpert',
        userName: customerName,
      };
  } else if (txIsJobDescriptionExpire(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.jobDescriptionExpiredClient',
        userName: expertName,
      }
      : {
        icon: 'declined',
        id: 'JobDescription.jobDescriptionExpiredExpert',
        userName: customerName,
      };
  } else if (txIsRejectedJob(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.jobRejectedClient',
        userName: expertName,
        reason: rejectionReason
      }
      : {
        icon: 'declined',
        id: 'JobDescription.jobRejectedExpert',
      };
  } else if (txIsJobApplicationDeclined(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.applicationDeclinedClient',
        userName: expertName,
        reason: rejectionReason
      }
      : {
        icon: 'declined',
        id: 'JobDescription.applicationDeclinedExpert',
        reason: rejectionReason
      };
  } else if (txIsRequestFirstInteview(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: meetingDate ? 'JobDescription.firstInterviewClient' : 'JobDescription.firstMeetingClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: meetingDate ? 'JobDescription.firstInterviewExpert' : 'JobDescription.firstMeetingExpert',
        userName: customerName,
      };
  } else if (txIsConfirmFirstInteview(tx)) {
    return {
      icon: 'accepted',
      id: 'JobDescription.firstMeetingConfirm',
    };
  } else if (txIsDeclinedInteview(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.firstInterviewDeclinedClient',
        userName: expertName,
        reason: interviewDeclinedReason
      }
      : {
        icon: 'declined',
        id: 'JobDescription.firstInterviewDeclineExpert',
        userName: customerName,
      };
  } else if (txIsRequestCaseStudy(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: 'JobDescription.caseStudyPendingClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: 'JobDescription.caseStudyPendingExpert',
        userName: customerName,
      };
  } else if (txIsDeclinedCaseStudy(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.caseStudyDeclinedClient',
        userName: expertName,
        reason: caseStudyDeclinedReason
      }
      : {
        icon: 'declined',
        id: 'JobDescription.caseStudyDeclinedExpert',
        userName: customerName,
      };
  } else if (txIsSubmittedCaseStudy(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: 'JobDescription.caseStudySubmittedClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: 'JobDescription.caseStudySubmittedExpert'
      };
  } else if (txIsRequestSecondInteview(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: meetingDate ? 'JobDescription.secondInterviewClient' : 'JobDescription.secondMeetingClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: meetingDate ? 'JobDescription.secondInterviewExpert' : 'JobDescription.secondMeetingExpert',
        userName: customerName,
      };
  } else if (txIsConfirmSecondInteview(tx)) {
    return {
      icon: 'accepted',
      id: 'JobDescription.secondMeetingConfirm',
    };
  } else if (txIsJobOfferSend(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: 'JobDescription.jobOfferSendClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: 'JobDescription.jobOfferSendExpert',
        userName: customerName,
      };
  } else if (txIsJobOfferAccepted(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: 'JobDescription.jobOfferAcceptedClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: 'JobDescription.jobOfferAcceptedExpert',
      };
  } else if (txIsJobOfferDeclined(tx)) {
    return isClient
      ? {
        icon: 'declined',
        id: 'JobDescription.jobOfferDeclinedClient',
        userName: expertName,
      }
      : {
        icon: 'declined',
        id: 'JobDescription.jobOfferDeclinedExpert',
      };
  } else if (txIsContractSend(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: isOfflineContract ? 'JobDescription.offlineContractMessage' : 'JobDescription.contractOfferedClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: isOfflineContract ? 'JobDescription.offlineContractMessage' : 'JobDescription.contractOfferedExpert',
        userName: customerName,
      };
  } else if (txIsContractSigned(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: 'JobDescription.contractSignedClient',
        userName: expertName,
      }
      : {
        icon: 'accepted',
        id: 'JobDescription.contractSignedExpert',
        userName: customerName,
      };
  } else if (txIsRequestedOfflinePayment(tx)) {
    return isClient
      ? {
        icon: 'accepted',
        id: 'JobDescription.offlinePaymentRequestedClient',
      }
      : {
        icon: 'accepted',
        id: 'JobDescription.offlinePaymentRequestedExpert',
      };
  } else if (txIsConfirmOfflinePayment(tx)) {
    return {
      icon: 'completed',
      id: 'JobDescription.offlinePaymentCompleted',
    };
  } else if (txIsOneReviewGiven(tx)) {
    return {
      icon: 'review',
      id: 'TransactionPanel.oneReviewGiven',
    };
  } else if (txIsBothReviewGiven(tx)) {
    return {
      icon: 'review',
      id: 'TransactionPanel.bothReviewGiven',
    };
  } else {
    return { id: 'TransactionPanel.loading' }
  }
}

const JobDescriptionOverview = ({ transaction, intl, isFullTimeJob }) => {
  const {
    id: briefId,
    title,
    aboutCompany,
    isRemoteLocation,
    seniority,
    description,
    responsibility,
    skill,
    isExperienceRequired,
    experience,
    jobLocation,
    industrySector,
    attachmentFile,
    ctc,
  } = transaction?.attributes?.protectedData?.brief || {};

  const location = isRemoteLocation ? 'This is a remote job.' : jobLocation?.search;
  const jobExperience = isExperienceRequired ? experience : 'Not application';
  const { currency, maxPrice, minPrice } = ctc ?? {}
  const maxMoney = ctc && new Money(Number(maxPrice.replace(/[^a-z0-9-]/g, '')) * 100, currency);
  const minMoney = ctc && new Money(Number(minPrice.replace(/[^a-z0-9-]/g, '')) * 100, currency);

  const { expectedSalary, expectedSalaryCurrency, lastSalary, lastSalaryCurrency, reason, resume } = transaction?.attributes?.metadata?.proposal || {};
  const lastSalaryMoney = lastSalary && new Money(Number(lastSalary.replace(/[^a-z0-9-]/g, '')) * 100, lastSalaryCurrency);
  const expectedSalaryMoney = expectedSalary && new Money(Number(expectedSalary.replace(/[^a-z0-9-]/g, '')) * 100, expectedSalaryCurrency);

  if (!briefId) return null;

  return (
    <>
      {/* Job Description Details */}
      <div className={css.briefCardWrapper}>
        <div className={css.JobDescriptionHeading}>
          <h4 className={css.mainHeading}>
            <FormattedMessage id="JobDescriptionOverview.jobDescriptionLabel" />
          </h4>
          <span className={css.briefType}>{isFullTimeJob ? FULL_TIME_EMPLOYMENT : SECONDMENT}</span>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.aboutCompany" /></h5>
          <div className={css.briefDescription}>{aboutCompany}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.location" /></h5>
          <div className={css.briefDescription}>{location}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.designation" /></h5>
          <div className={css.briefDescription}>{title}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.seniority" /></h5>
          <div className={css.briefDescription} style={{ textTransform: 'capitalize' }}>{seniority}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.jobDescription" /></h5>
          <div className={css.briefDescription}>{description}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.responsibility" /></h5>
          <div className={css.briefDescription}>{responsibility ?? emptySpace}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.skillsRequired" /></h5>
          <div className={css.briefDescription}>{skill?.join(', ') ?? emptySpace}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.experience" /></h5>
          <div className={css.briefDescription}>{jobExperience} years</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.industrySector" /></h5>
          <div className={css.briefDescription}>{industrySector ?? emptySpace}</div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.ctcBudget" /></h5>
          <div className={css.briefDescription}>
            {minMoney && formatMoney(intl, minMoney)} - {maxMoney && formatMoney(intl, maxMoney)}
          </div>
        </div>
        <div className={css.briefCard}>
          <h5><FormattedMessage id="JobListing.attachment" /></h5>
          {attachmentFile?.name ? <FileView file={attachmentFile} /> : emptySpace}
        </div>
      </div>
      {/* Job Application Details */}
      {resume?.name && (
        <div className={css.briefCardWrapper}>
          <h4 className={css.mainHeading}>
            <FormattedMessage id="JobDescriptionOverview.jobApplicationLabel" />
          </h4>
          <div className={css.briefCard}>
            <h5><FormattedMessage id="JobDescriptionOverview.jobReasonLabel" /></h5>
            <div className={css.briefDescription}>{reason}</div>
          </div>
          <div className={css.briefCard}>
            <h5><FormattedMessage id="JobDescriptionOverview.resumeLabel" /></h5>
            {resume?.name && <FileView file={resume} />}
          </div>
          {lastSalary && (
            <div className={css.briefCard}>
              <h5><FormattedMessage id="JobDescriptionOverview.lastSalaryLabel" /></h5>
              <div className={css.briefDescription}>
                {lastSalaryMoney && formatMoney(intl, lastSalaryMoney)}
              </div>
            </div>
          )}
          <div className={css.briefCard}>
            <h5>
              <FormattedMessage
                id={isFullTimeJob ? "JobDescriptionOverview.expectedSalaryLabel" : 'JobDescriptionOverview.expectedSalaryMonthlyLabel'}
              />
            </h5>
            <div className={css.briefDescription}>
              {expectedSalaryMoney && formatMoney(intl, expectedSalaryMoney)}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const JobDescription = (props) => {
  const {
    transaction,
    isClient,
    customerName,
    expertName,
    onManageDisableScrolling,
    intl,
    actionInProgress,
    openInterviewModal,
    currentUser,
    expertEmail,
    openReviewModal,
    notifications,
    history,
    customerEmail
  } = props;
  
  const transactionMetadata = transaction?.attributes?.metadata;
  const lastTransition = transaction?.attributes?.lastTransition;
  const clientTransition = customerTranstions.includes(lastTransition)
  const expertTransition = expertTranstions.includes(lastTransition)
  const isNextTranstionPending = isClient ? clientTransition : expertTransition;

  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState('');
  const [isTaskPending, setIsTaskPending] = useState(isNextTranstionPending);
  const [skipSteps, setSkipSteps] = useState({
    openModal: false,
    steps: [],
    eventHandler: () => { }
  });

  const [alertModal, setAlertModal] = useState({
    isOpen: false,
    modalName: '',
    message: '',
    warning: '',
    eventHandler: () => { }
  });
  const [showMenuItem, setShowMenuItem] = useState(false);

  const txId = transaction?.id?.uuid;
  const processName = transaction?.attributes?.processName;
  const clientEmail = getUserDetails(currentUser)?.email;
  const transitions = transaction?.id && transaction.attributes.transitions.map(t => t.transition);
  const { 
    firstInterviewData, 
    proposal, 
    caseStudyDetails,
    submission, 
    jobOfferDetails, 
    zohoAccessToken: accessToken, 
    contractDetails,
    isContractSkipped
  } = transaction?.id ? transactionMetadata : {};

  const contractType = transaction?.attributes?.protectedData?.brief?.contractType;
  const isFullTimeJob = contractType === FULLTIME_KEY;
  const isOfflineContract = contractDetails?.contractType === OFFLINE;
  const isOnlineContract = contractDetails?.contractType === ONLINE;

  const meetingDate = firstInterviewData?.date && moment(firstInterviewData?.date).format("DD MMM YYYY");
  const meetingTime = firstInterviewData?.date && moment(firstInterviewData?.date).format('LT');
  const caseStudyDeadline = caseStudyDetails && 
  `${moment(caseStudyDetails?.deadline).format("ll")} at ${moment(caseStudyDetails?.deadline).format('LT')}`;
  const deadlineDueDays = caseStudyDetails && moment(caseStudyDetails?.deadline).diff(moment(), 'days');
  const access_token = accessToken?.access_token;
  const lastTransitionedAt = transaction && transaction.id && transaction?.attributes?.lastTransitionedAt;
  const isReviewd = txIsReviewed(transaction);
  const chatExpirationTime = isReviewd && moment(lastTransitionedAt).add(30, 'days').format('LLLL');
  const isChainedTransaction = transactionMetadata?.jobTransactionId;

  //Transitions 
  const isApplicationSend = transitions.includes(TRANSITION_SEND_JOB_APPLICATION)
    || transitions.includes(TRANSITION_ACCEPT_JOB_APPLICATION_AFTER_JOB);
  const isApplicationAccepted = transitions.includes(TRANSITION_ACCEPT_JOB_APPLICATION);
  const isJobDescriptionDeclined = txIsJobDescriptionDeclined(transaction);
  const isApplicationDeclined = txIsJobApplicationDeclined(transaction);
  const isJobDeclined = txIsRejectedJob(transaction);
  const isCaseStudySubmitted = transitions.includes(TRANSITION_SUBMIT_CASE_STUDY);
  const isCaseStudyDeclined = txIsDeclinedCaseStudy(transaction);
  const isJobApplicationExpired = txIsJobApplicationExpire(transaction);
  const isJobDescriptionExpired = txIsJobDescriptionExpire(transaction);
  const isFirstInterviewDeclined = lastTransition === TRANSITION_DECLINE_FIRST_INTERVIEW;
  const isSecondInterviewDeclined = lastTransition === TRANSITION_DECLINE_SECOND_INTERVIEW;
  const isJobOfferSend = transitions.includes(TRANSITION_SEND_JOB_OFFER);
  const isSecondInterview = !isSecondInterviewDeclined && transitions.includes(TRANSITION_REQUEST_SECOND_INTERVIEW) || isJobOfferSend;
  const isCaseStudyRequested = !isCaseStudyDeclined && transitions.includes(TRANSITION_REQUEST_CASE_STUDY) || isSecondInterview || isJobOfferSend;
  const isFirstInterview = !isFirstInterviewDeclined && (transitions.includes(TRANSITION_REQUEST_FIRST_INTERVIEW)
    || isJobOfferSend || transitions.includes(TRANSITION_REQUEST_CASE_STUDY) || isSecondInterview);
  const isFirstInterviewConfirmed = transitions.includes(TRANSITION_CONFIRM_FIRST_INTERVIEW)
    || isJobOfferSend || transitions.includes(TRANSITION_REQUEST_CASE_STUDY) || isSecondInterview;
  const isSecondInterviewConfirmed = transitions.includes(TRANSITION_CONFIRM_SECOND_INTERVIEW) || isJobOfferSend;
  const isJobOfferAccepted = transitions.includes(TRANSITION_ACCEPT_JOB_OFFER);
  const isJobOfferDeclined = txIsJobOfferDeclined(transaction);
  const isContractSend = transitions.includes(TRANSITION_REQUEST_CONTRACT_SIGNATURE) || isContractSkipped;
  const isContractSigned = transitions.includes(TRANSITION_COMFIRM_CONTRACT_SIGNATURE) || isContractSkipped;
  const isOfflinePaymentRequested = transitions.includes(TRANSITION_REQUEST_OFFLINE_PAYMENT)
    || transitions.includes(TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT);
  const isOfflinePaymentCompleted = transitions.includes(TRANSITION_CONFIRM_OFFLINE_PAYMENT);
  const isClientReviewGiven = transitions.includes(TRANSITION_REVIEW_1_BY_CUSTOMER) || transitions.includes(TRANSITION_REVIEW_2_BY_CUSTOMER);
  const isExpertReviewGiven = transitions.includes(TRANSITION_REVIEW_1_BY_PROVIDER) || transitions.includes(TRANSITION_REVIEW_2_BY_PROVIDER);

  const hideMenu = isApplicationDeclined || isJobDeclined || isJobDescriptionDeclined || isJobOfferDeclined 
    || isJobApplicationExpired || isJobDescriptionExpired;
  const stateData = stateDataFn(transaction, isClient, customerName, expertName, meetingDate, isOfflineContract);
  const showExpertActionButton = txIsJobDescriptionSent(transaction)
    || (!isJobOfferAccepted && isApplicationSend)
    || (txIsRequestFirstInteview(transaction) || txIsRequestSecondInteview(transaction))
    || txIsRequestCaseStudy(transaction)
    || txIsJobOfferSend(transaction)
    || (txIsContractSend(transaction) && !isOfflineContract)
  // || (isOfflinePaymentCompleted && !isExpertReviewGiven);

  const firstInterviewClassess = classNames(css.menuItem, isFirstInterviewConfirmed && css.disabled, !isFirstInterview && css.disable);
  const secondInterviewClassess = classNames(css.menuItem, isSecondInterviewConfirmed && css.disabled, !isSecondInterview && css.disable);
  const disableRejectJob = processName === TRANSACTION_APPLY_JOB_PROCESS ? true : isChainedTransaction ? !isFirstInterview : false;

  async function handleApplyJob(values) {
    const metadata = {
      isTransactionRead: false,
      proposal: {
        ...values,
        proposalSentAt: Date.now(),
      },
    };

    dispatch(sendProposal(transaction, { metadata }, null, TRANSITION_SEND_JOB_APPLICATION))
      .then(response => {
        if (response?.data?.data?.id){
          updateFirmUserDetails({
            action: PRIVATELY_SHARED_JOB_APPLICATIONS,
            userEmail: currentUser?.attributes?.email,
            listingId: transaction?.id?.uuid
          })
          updateFirmUserDetails({
            action: RECRUITMENTS_UNDERWAY,
            userEmail: customerEmail,
            listingId: transaction?.id?.uuid
          })
          setOpenModal('')
        }
      })
  }

  async function handleDeclineApplication({ reason }) {
    const transition = isClient
      ? (isApplicationAccepted ? TRANSITION_DECLINE_JOB_APPLICATION : TRANSITION_DECLINE_APPLICATION_AFTER_JOB)
      : (isApplicationAccepted ? TRANSITION_REJECT_JOB_INVITE : TRANSITION_REJECT_JOB_AFTER_APPLICATION);
    dispatch(declineProposal(transaction, transition, reason)).then(response => {
      if (response?.data?.data?.id) {
        setOpenModal('')
        deleteRecruitment({
          jobId:transaction.id.uuid,
          userEmail: isClient ? currentUser.attributes.email : customerEmail
        })
      }
    })
  }

  async function handleDeclineJobDesc(transition) {
    dispatch(declineBrief(transaction, transition))
    setAlertModal({})
  }

  async function handleDeclineCaseStudy({ reason }) {
    const param = { submission: {...submission, declineReason: reason }}
    const transition = TRANSITION_DECLINE_CASE_STUDY;

    dispatch(requestCaseStudy({ transaction, param, isTransition: true, transition }))
    .then(response => {
      if (response?.data?.data?.id) setAlertModal({})
    })
  }

  async function handleDeclineInterview({ reason }) {
    const param = { firstInterviewData: {...firstInterviewData, declineReason: reason }}
    const transition = isSecondInterview ? TRANSITION_DECLINE_SECOND_INTERVIEW : TRANSITION_DECLINE_FIRST_INTERVIEW;

    dispatch(requestCaseStudy({ transaction, param, isTransition: true, transition }))
    .then(response => {
      if (response?.data?.data?.id) setAlertModal({})
    })
  }

  async function handleConfirmContract() {
    const transition = TRANSITION_COMFIRM_CONTRACT_SIGNATURE;
    await dispatch(handleJobTransition(transaction, transition));
    setShowMenuItem(false)
  }

  async function handleOfflinePayment(values = {}) {
    const {paymentType } = values;
    const transition = !isContractSkipped 
      ? TRANSITION_REQUEST_OFFLINE_PAYMENT : TRANSITION_REQUEST_OFFLINE_PAYMENT_WITHOUT_CONTRACT;

    const txId = transaction?.id?.uuid;
    const currentPathForEmail = `${process.env.REACT_APP_CANONICAL_ROOT_URL}/t/${txId}/chat`
    const param = { paymentType: paymentType === 'both' ? BOTH_EXPERT_AND_INSIGHTGIG : ONLY_INSIGHTGIG }

    const emailParams = {
      currentPath: currentPathForEmail,
      customerName: customerName?.split(' ')[0],
      providerName: expertName?.split(' ')[0],
      userEmail: expertEmail,
      step: paymentType === 'both' ? BOTH_EXPERT_AND_INSIGHTGIG : ONLY_INSIGHTGIG
    }
    const response = await dispatch(requestCaseStudy({ transaction, isTransition: true, transition, param }))
    if (response?.data?.data?.id) {
      setOpenModal('')
      sendEmailUsingZepto(emailParams)
    }
  }

  async function handleConfirmInterview(interviewIndex) {
    const transition = interviewIndex === 1 ? TRANSITION_CONFIRM_FIRST_INTERVIEW : TRANSITION_CONFIRM_SECOND_INTERVIEW;
    await dispatch(handleJobTransition(transaction, transition))
    setShowMenuItem(false)
  }

  return (
    <div className={css.accordionWrapper}>
      <NotificationBar
        history={history}
        currentUser={currentUser}
        notifications={notifications}
        currentTransaction={transaction} />
      <div className={css.statusBar}>
        <h4><FormattedMessage id="TransactionPanel.status" /></h4>
        <div className={css.rightSideStatus}>
          <div className={css.responseText}>
            <span className={css.statusIcon}><IconCard brand={stateData.icon} /></span>
            <span className={css.statusText}>
              <FormattedMessage
                id={stateData.id}
                values={{
                  userName: stateData.userName,
                  date: meetingDate,
                  time: meetingTime,
                  days: chatExpirationTime,
                  reason: (
                    <div className={css.statusText}>
                      <span style={{ fontWeight: '500' }}>Reason:</span> {stateData.reason}
                    </div>
                  ),
                }}
              />
              {!isClient && txIsRequestCaseStudy(transaction) && caseStudyDeadline && (
                <div className={css.statusText}>
                  The deadline is <span style={{ fontWeight: '500' }}> {caseStudyDeadline}.</span>
                </div>
              )}
            </span>
          </div>
          <Menu className={classNames(css.menu, hideMenu && css.hideMenu)}>
            <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
              <div className={css.iconWrapper} onClick={() => setIsTaskPending(false)}>
                {isTaskPending ? <LoadingDots /> : <MenuIcon className={css.menuIcon} />}
              </div>
            </MenuLabel>
            {isClient ? (
              <MenuContent
                rootClassName={css.menuContent}
                contentClassName={classNames(css.content, !isApplicationSend && css.disable)}
              >
                <MenuItem key="selectAction" className={css.firstChild}>
                  <FormattedMessage id='JobDescription.menuSelectAction' />
                </MenuItem>
                <MenuItem key="viewApplication">
                  <span onClick={() => setOpenModal(APPLICATION_MODAL)}>
                    <FormattedMessage id='JobDescription.menuViewApplication' />
                  </span>
                </MenuItem>
                {isCaseStudySubmitted && (
                  <MenuItem key="viewCaseStudy">
                    <span onClick={() => setOpenModal(SUBMIT_CASE_MODAL)}>
                      <FormattedMessage id='JobDescription.menuViewCaseStudy' />
                    </span>
                  </MenuItem>
                )}
                <MenuItem key="inviteToFirstInterview">
                  <div
                    className={classNames(css.menuItem, css.topBorder)}
                    onClick={() => !isFirstInterview && openInterviewModal(1)}
                  >
                    {isFirstInterview && <input type='checkbox' checked={true} readOnly></input>}
                    <span><FormattedMessage id='JobDescription.menuStep1' /></span>
                  </div>
                </MenuItem>
                <MenuItem key="confirmFirstInterview">
                  <span onClick={() => setShowMenuItem('firstInterview')} className={firstInterviewClassess}>
                    {isFirstInterviewConfirmed && <input type='checkbox' checked={true} readOnly ></input>}
                    <FormattedMessage id='JobDescription.menuStep2' />
                  </span>
                  {showMenuItem === 'firstInterview' && (
                    <OutsideClickHandler onOutsideClick={() => setShowMenuItem(false)} className={css.outsideButton}>
                      <button onClick={() => handleConfirmInterview(1)}>
                        {actionInProgress ? <IconSpinner /> : <FormattedMessage id='JobDescription.menuConfirmInterview' />}
                      </button>
                    </OutsideClickHandler>
                  )}
                </MenuItem>
                <MenuItem key="sendCaseStudy">
                  <div
                    className={classNames(css.menuItem, (isFirstInterview && !isFirstInterviewConfirmed) && css.disable)}
                    onClick={() => {
                      if (!isFirstInterviewConfirmed) {
                        setSkipSteps({
                          ...skipSteps,
                          openModal: true,
                          steps: [STEP_FIRST_INTERVIEW],
                          eventHandler: () => setOpenModal(CASE_STUDY_MODAL)
                        })
                      } else if (!isCaseStudyRequested) setOpenModal(CASE_STUDY_MODAL)
                    }}
                  >
                    {isCaseStudyRequested && <input type='checkbox' checked={true} readOnly ></input>}
                    <span><FormattedMessage id='JobDescription.menuStep3' /></span>
                  </div>
                </MenuItem>
                <MenuItem key="inviteToSecondInterview">
                  <div
                    className={classNames(css.menuItem, (isFirstInterview && !isFirstInterviewConfirmed) && css.disable)}
                    onClick={() => {
                      if (!isCaseStudyRequested) {
                        setSkipSteps({
                          ...skipSteps,
                          openModal: true,
                          steps: [
                            !isFirstInterviewConfirmed && STEP_FIRST_INTERVIEW,
                            STEP_SEND_CASE_STUDY
                          ],
                          eventHandler: () => openInterviewModal(2)
                        })
                      } else if (!isSecondInterview) openInterviewModal(2)
                    }}
                  >
                    {isSecondInterview && <input type='checkbox' checked={true} readOnly></input>}
                    <span><FormattedMessage id='JobDescription.menuStep4' /></span>
                  </div>
                </MenuItem>
                <MenuItem key="confirmSecondInterview">
                  <span onClick={() => setShowMenuItem('secondInterview')} className={secondInterviewClassess}>
                    {isSecondInterviewConfirmed && <input type='checkbox' checked={true} readOnly ></input>}
                    <FormattedMessage id='JobDescription.menuStep5' />
                  </span>
                  {showMenuItem === 'secondInterview' && (
                    <OutsideClickHandler onOutsideClick={() => setShowMenuItem(false)} className={css.outsideButton}>
                      <button onClick={() => handleConfirmInterview(2)}>
                        {actionInProgress ? <IconSpinner /> : <FormattedMessage id='JobDescription.menuConfirmInterview' />}
                      </button>
                    </OutsideClickHandler>
                  )}
                </MenuItem>
                <MenuItem key="sendJobOffer">
                  <div
                    className={classNames(css.menuItem,
                      ((isFirstInterview && !isFirstInterviewConfirmed) || (isSecondInterview && !isSecondInterviewConfirmed)) && css.disable)
                    }
                    onClick={() => {
                      if (!isSecondInterviewConfirmed) {
                        setSkipSteps({
                          ...skipSteps,
                          openModal: true,
                          steps: [
                            !isFirstInterviewConfirmed && STEP_FIRST_INTERVIEW,
                            !isCaseStudyRequested && STEP_SEND_CASE_STUDY,
                            STEP_SECOND_INTERVIEW
                          ],
                          eventHandler: () => setOpenModal(SEND_OFFER_MODAL)
                        })
                      } else if (!isJobOfferSend) setOpenModal(SEND_OFFER_MODAL)
                    }}
                  >
                    {isJobOfferSend && <input type='checkbox' checked={true} readOnly></input>}
                    <span><FormattedMessage id='JobDescription.menuStep6' /></span>
                  </div>
                </MenuItem>
                <MenuItem key="signContract">
                  <div
                    className={classNames(css.menuItem, !isJobOfferAccepted && css.disable)}
                    onClick={() => {
                      if (isContractSend) setShowMenuItem('contract')
                      else setOpenModal(CONTRACT_MODAL)
                      if (!isContractSkipped && !access_token) dispatch(zohoAccessToken(txId))
                    }}
                  >
                    {txIsContractSend(transaction) ? (
                      <>
                        <span className={css.viewContractRow}>
                          <FormattedMessage id={isOnlineContract ? 'JobDescription.contractSignedOnline' : 'JobDescription.menuConfirmSignature'} />
                          {isOfflineContract && <span className={css.alertMark}>!</span>}
                        </span>
                        {(showMenuItem === 'contract' && isOfflineContract) && (
                          <OutsideClickHandler onOutsideClick={() => setShowMenuItem(false)} className={css.outsideButton}>
                            <button onClick={handleConfirmContract}>
                              {actionInProgress ? <IconSpinner /> : <FormattedMessage id='JobDescription.contractSigned' />}
                            </button>
                          </OutsideClickHandler>
                        )}
                      </>
                    ) : (
                      <span className={css.menuItem}>
                        {isContractSigned && <input type='checkbox' checked={true} readOnly></input>}
                        <FormattedMessage id={'JobDescription.menuStep7'} />
                      </span>
                    )}
                  </div>
                </MenuItem>
                <MenuItem key="payment">
                  <div
                    className={classNames(css.menuItem, !isContractSigned && css.disable)}
                    onClick={() => !isOfflinePaymentRequested && setOpenModal(PAYMENT_MODAL)}
                  >
                    {isOfflinePaymentCompleted && <input type='checkbox' checked={true} readOnly></input>}
                    <span><FormattedMessage id='JobDescription.menuStep8' /></span>
                  </div>
                </MenuItem>
                <MenuItem key="rejectApplication" className={classNames(css.lastChild, isJobOfferSend && css.disable)}>
                  <span onClick={() => setOpenModal(REJECT_MODAL)}>
                    <FormattedMessage id='JobDescription.menuRejectApplication' />
                  </span>
                </MenuItem>
                {/* {!isOfflinePaymentCompleted ? (
                  <>  
                    
                  </>
                ) : (
                  <MenuItem key="leaveReview">
                    <div className={classNames(css.menuItem, isClientReviewGiven && css.disable)} 
                      onClick={() => openReviewModal()}
                    >
                      <FormattedMessage id='JobDescription.menuLeaveReview' />
                    </div>
                  </MenuItem>
                )} */}
              </MenuContent>
            ) : (
              <MenuContent rootClassName={classNames(css.menuContent, css.expertMenu)} contentClassName={css.content}>
                <MenuItem key="selectAction" className={css.firstChild}>
                  {showExpertActionButton ? 'Select action:' : 'No actions possible'}
                </MenuItem>
                {!isApplicationSend && (
                  <MenuItem key="applyJob">
                    <span onClick={() => setOpenModal(APPLICATION_FORM)}>
                      <FormattedMessage id='JobDescription.menuApplyForJob' />
                    </span>
                  </MenuItem>
                )}
                {!isApplicationSend && (
                  <MenuItem key="declineJob">
                    <span onClick={() => setAlertModal({
                      isOpen: true,
                      message: 'JobDescription.declineJobInviteMessage',
                      warning: 'JobDescription.declineJobInviteWarning',
                      eventHandler: () => handleDeclineJobDesc(TRANSITION_DECLINE_JOB_DESCRIPTION)
                    })}>
                      <FormattedMessage id='JobDescription.menuDeclineInvite' />
                    </span>
                  </MenuItem>
                )}
                {(txIsRequestFirstInteview(transaction) || txIsRequestSecondInteview(transaction)) && (
                  <MenuItem key="declineInterview">
                    <span onClick={() => setAlertModal({
                      isOpen: true,
                      modalName: REJECT_MODAL,
                      message: 'JobDescription.declineInterviewReasonLabel',
                      warning: 'JobDescription.declineInterviewMessage',
                      eventHandler: handleDeclineInterview
                    })}>
                      <FormattedMessage id='JobDescription.menuDeclineInterview' />
                    </span>
                  </MenuItem>
                )}
                {txIsRequestCaseStudy(transaction) && (
                  <MenuItem key="viewCaseStudy">
                    <span onClick={() => setOpenModal(SUBMIT_CASE_MODAL)}>
                      <FormattedMessage id='JobDescription.menuSubmitCaseStudy' />
                    </span>
                  </MenuItem>
                )}
                {txIsRequestCaseStudy(transaction) && (
                  <MenuItem key="declineCaseStudy">
                    <span onClick={() => setAlertModal({
                      isOpen: true,
                      modalName: REJECT_MODAL,
                      message: 'JobDescription.declineCaseStudyReasonLabel',
                      warning: 'JobDescription.declineCaseStudyMessage',
                      eventHandler: handleDeclineCaseStudy
                    })}>
                      <FormattedMessage id='JobDescription.menuDeclineCaseStudy' />
                    </span>
                  </MenuItem>
                )}
                {txIsJobOfferSend(transaction) && (
                  <MenuItem key="viewJobOffer">
                    <span onClick={() => setOpenModal(VIEW_OFFER_MODAL)}>
                      <FormattedMessage id='JobDescription.menuViewJobOffer' />
                    </span>
                  </MenuItem>
                )}
                {(txIsContractSend(transaction) && !isOfflineContract) && (
                  <MenuItem key="viewContract">
                    <span onClick={() => setShowMenuItem('viewContract')}>
                      <FormattedMessage id='JobDescription.menuViewContract' />
                    </span>
                    {showMenuItem === 'viewContract' && (
                      <OutsideClickHandler onOutsideClick={() => setShowMenuItem(false)} className={css.outsideButton}>
                        <label>
                          <FormattedMessage id='JobDescription.menuContractMessage' />
                        </label>
                      </OutsideClickHandler>
                    )}
                  </MenuItem>
                )}
                {/* {(isOfflinePaymentCompleted && !isExpertReviewGiven) && (
                  <MenuItem key="leaveReview">
                    <div className={classNames(css.menuItem, isExpertReviewGiven && css.disable)} 
                      onClick={() => openReviewModal()}
                    >
                      <FormattedMessage id='JobDescription.menuLeaveReview' />
                    </div>
                  </MenuItem>
                )} */}
                {isJobOfferAccepted ? null : (isApplicationSend || isApplicationAccepted) && (
                  <MenuItem key="rejectJob" className={classNames(css.lastChild, disableRejectJob && css.disable)}>
                    <span onClick={() => setOpenModal(REJECT_MODAL)}>
                      <FormattedMessage id='JobDescription.rejectJob' />
                    </span>
                  </MenuItem>
                )}
              </MenuContent>
            )}
          </Menu>
        </div>
      </div>
      <JobDescriptionOverview transaction={transaction} intl={intl} isFullTimeJob={isFullTimeJob} />

      {/* View Application Modal */}
      <Modal
        id="JobDescription.viewApplication"
        containerClassName={css.modal}
        isOpen={openModal === APPLICATION_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <ViewApplicationModal
          proposal={proposal}
          isJobOfferSend={isJobOfferSend}
          isFirstInterview={isFirstInterview}
          setOpenModal={setOpenModal}
          openInterviewModal={openInterviewModal}
          isFullTimeJob={isFullTimeJob}
        />
      </Modal>
      {/* Send Application Modal */}
      <ModalApplication
        id="ModalApplication"
        isOpen={openModal === APPLICATION_FORM}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={handleApplyJob}
        inProgress={actionInProgress}
        isFullTimeJob={isFullTimeJob}
      />
      {/* Reject applicaiton modal */}
      <Modal
        id="JobDescription.rejectApplication"
        isOpen={openModal === REJECT_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <RejectApplicationForm
          onSubmit={handleDeclineApplication}
          closeModal={() => setOpenModal('')}
          submitProgress={actionInProgress}
          isClient={isClient}
        />
      </Modal>
      {/* Request Case Study modal */}
      <Modal
        id="JobDescription.requestCaseStudy"
        isOpen={openModal === CASE_STUDY_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <CaseStudyForm
          onSubmit={() => {}}
          dispatch={dispatch}
          submitProgress={actionInProgress}
          transaction={transaction}
          setOpenModal={setOpenModal}
        />
      </Modal>
      {/* View Case Study Modal */}
      <Modal
        id="JobDescription.submitCaseStudy"
        isOpen={openModal === SUBMIT_CASE_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <SubmitCaseStudyForm
          caseStudyDetails={caseStudyDetails}
          caseStudyDeadline={caseStudyDeadline}
          deadlineDueDays={deadlineDueDays}
          submitProgress={actionInProgress}
          onSubmit={() => {}}
          dispatch={dispatch}
          isCaseStudySubmitted={isCaseStudySubmitted}
          submission={submission}
          transaction={transaction}
          setOpenModal={setOpenModal}
        />
      </Modal>
      {/* Send Offer Modal */}
      <Modal
        id="JobDescription.sendOffer"
        isOpen={openModal === SEND_OFFER_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <SendOfferForm
          submitProgress={actionInProgress}
          onSubmit={() => {}}
          dispatch={dispatch}
          setOpenModal={setOpenModal}
          transaction={transaction}
          currentUser={currentUser}
          isFullTimeJob={isFullTimeJob}
        />
      </Modal>
      {/* Skip Step Modal */}
      <Modal
        id="JobDescription.steps"
        isOpen={skipSteps.openModal}
        onClose={() => setSkipSteps({ ...skipSteps, openModal: false })}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.skipStepModal}>
          <h2><FormattedMessage id='JobDescription.skipStepTitle' /></h2>
          <p>
            <FormattedMessage id='JobDescription.skipStepMessage' />
            <span style={{ position: 'relative', top: '1.7px' }}>-</span>
          </p>
          {skipSteps?.steps?.map(step => <label key={step}>{step}</label>)}
          <p className={css.confirmStep}><FormattedMessage id='JobDescription.skipStepConfirm' /></p>

          <div className={css.buttonWrapper}>
            <span className={css.buttonCancel} onClick={() => setSkipSteps({ ...skipSteps, openModal: false })}>
              Cancel
            </span>
            <PrimaryButton className={css.buttonSubmit} onClick={() => {
              setSkipSteps({ ...skipSteps, openModal: false })
              skipSteps.eventHandler();
            }}>
              Yes, skip
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      {/* View Job Offer Modal */}
      <Modal
        id="JobDescription.viewOffer"
        isOpen={openModal === VIEW_OFFER_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ViewJobOfferModal
          isFullTimeJob={isFullTimeJob}
          closeModal={() => setOpenModal('')}
          jobOfferDetails={jobOfferDetails}
          actionInProgress={actionInProgress}
          txId={txId}
          isJobOfferAccepted={isJobOfferAccepted}
          transaction={transaction}
          setAlertModal={setAlertModal}
        />
      </Modal>
      {/* Send Contract Modal */}
      <Modal
        id="JobDescription.sendContract"
        isOpen={openModal === CONTRACT_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <SignContractForm
          txId={txId}
          closeModal={() => setOpenModal('')}
          initialValues={{ signatories: ['expertSign'] }}
          submitProgress={actionInProgress}
          access_token={access_token}
          clientName={customerName}
          clientEmail={clientEmail}
          expertName={expertName}
          expertEmail={expertEmail}
          contractDetails={contractDetails}
          isContractSigned={isContractSigned}
          isClient={isClient}
          transaction={transaction}
          openPaymentForm={() => setOpenModal(PAYMENT_MODAL)}
        />
      </Modal>
      {/* Payment Modal */}
      <Modal
        id="JobDescription.paymentModal"
        isOpen={openModal === PAYMENT_MODAL}
        onClose={() => setOpenModal('')}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <JobPaymentForm
          closeModal={() => setOpenModal('')}
          submitProgress={actionInProgress}
          onSubmit={handleOfflinePayment}
          jobOfferDetails={jobOfferDetails}
          isFullTimeJob={isFullTimeJob}
        />
      </Modal>

      <Modal
        id="JobDescription.alertModal"
        isOpen={alertModal.isOpen}
        onClose={() => setAlertModal({})}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {alertModal.modalName === REJECT_MODAL ? (
          <RejectApplicationForm
            onSubmit={alertModal.eventHandler}
            closeModal={() => setAlertModal({})}
            submitProgress={actionInProgress}
            isClient={isClient}
            messageId={alertModal.warning}
            reasonLabelId={alertModal.message}
          />
        ) : (
          <div className={css.skipStepModal}>
            {alertModal.message && (
              <h2 style={{textAlign: 'center'}}><FormattedMessage id={alertModal.message} /></h2>
            )}
            {alertModal.warning && (
              <p style={{textAlign: 'center'}}><FormattedMessage id={alertModal.warning} /></p>
            )}
            <div className={css.buttonWrapper}>
              <span className={css.buttonCancel} onClick={() => setAlertModal({})}>
                Close
              </span>
              <PrimaryButton className={css.buttonSubmit} onClick={() => alertModal.eventHandler()}>
                Confirm
              </PrimaryButton>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default JobDescription