import { storeRawQuantitativeChat } from '../../components/OpenAIAppsPanel/gigAppsHelperFunction';
import { stopThreadRun } from '../../util/api';
import { getSocket } from '../../socket/socketConfig';

export const handleThreadResponse = (params) => {
  const { 
    id,
    type,
    email,
    threadId,
    question,
    displayQuestion,
    setLastItem,
    setIsLoading,
    handleError,
    isTransaction ,
    datasetSheetName,
    datasetFileId,
    datasetFileName,
    contextFileId,
    contextSheetName,
    contextFileName,
    fileExtension 
  } = params;

  try {
    const socket = getSocket();
    if (socket) {
      const responseString = `openaiStreamResponse_${threadId}`;
      socket.removeAllListeners(responseString);
      socket.removeAllListeners("quantAppStreamRequest");

      socket.emit('quantAppStreamRequest', { 
        id,
        email,
        question,
        threadId,
        appRoute: type,
        responseString,
        datasetSheetName,
        datasetFileId,
        datasetFileName,
        contextFileId,
        contextSheetName,
        contextFileName,
        fileExtension 
      });

      let answer = ''
      socket.on(responseString, (data) => {
        if(data?.runId) sessionStorage.setItem('runId', data?.runId)

        if (data?.error) {
          throw new Error('Error getting response!');
        } 
        else if (data?.response === 'stream-response-end') {
          const totalTokens = data?.totalTokens;
          // const cleanedAnswer = answer.replace(/(?!<\/?img[^>]*>|<\/?div\s+class=['"]quantAppQueryLoader['"][^>]*>)(>)/g, '').trim();
          storeRawQuantitativeChat({type, id, data: {question: displayQuestion, answer: answer}, isTransaction, totalTokens})
          setIsLoading(false);
          return;
        }
        answer = data?.response
        setLastItem({question: displayQuestion, answer: data?.response});
      });
    }
  } catch (error) {
    // console.log(error, 'Error handling the thread query!');
    handleError(error.message);
  }
};

export async function handleStopThreadStreamResponse(streamId){
  try{
    const socket = getSocket();
    const responseString = `openaiStreamResponse_${streamId}`;
    if(socket){
      socket.removeAllListeners(responseString);
      
      const runId = sessionStorage.getItem('runId');
      if(runId && streamId){
        stopThreadRun({threadId: streamId, runId})
      }
    }
  }
  catch(error){
    console.log(error, 'Error stopping stream!')
  }
  finally{
    sessionStorage.removeItem('runId')
  }
}

export async function handleStreamResponse(params){
  try{
    const {query, e2bSessionId, sessionId, setIsLoading, setLastItem, earlierMessages, email} = params;
    const url = `${process.env.REACT_APP_DJANGO_BACKEND_API}/handle_raw_quant_query`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, e2bSessionId, sessionId, earlierMessages, email }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // We get the stream from the response body
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false, answer = '';

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;

      // Decode the chunk and append it to the chunk variable
      const data = decoder.decode(value, { stream: true });

      // Process the chunk here (e.g., update the state, log it, etc.)
      answer += data?.replace(/data: /g, '');
      setLastItem({question: query, answer: answer});
    }
    
    console.log('Stream finished');
    setIsLoading(false);
  }
  catch(error){
    console.log(error, 'Error hanlding analyze stream response!')
  }
}