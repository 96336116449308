import React, { Component, useState } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, SecondaryButton, ValidationError, IconSpinner, IconClose, OutsideClickHandler } from '../../components';
import { propTypes } from '../../util/types';
import PinIcon from '../../assets/attachment.png';
import css from './SendMessageForm.module.css';
import moment from 'moment';
import { TRANSITION_BRIEF_DECLINE_PROPOSAL, TRANSITION_BRIEF_EXPIRE_PROPOSAL, TRANSITION_DECLINE_BRIEF, TRANSITION_DECLINE_PROPOSAL } from '../../util/transaction';
import emojiIcon from '../../assets/smile.svg'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
// import GifPicker from "gif-picker-react";
import gifIcon from '../../assets/gif.png'
import TenorGifViewer from './GifPicker';
import { v4 as uuidv4 } from 'uuid';
import Mentions from 'rc-mentions';
const { Option } = Mentions;
import './mention.css'
import { handleFileUpload, removeFileFromAzure } from '../../util/api';

const BLUR_TIMEOUT_MS = 100;

const IconSendMessage = () => {
  return (
    <svg
      className={css.sendIcon}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={css.strokeMatter} fill="none" fillRule="evenodd" strokeLinejoin="round">
        <path d="M12.91 1L0 7.003l5.052 2.212z" />
        <path d="M10.75 11.686L5.042 9.222l7.928-8.198z" />
        <path d="M5.417 8.583v4.695l2.273-2.852" />
      </g>
    </svg>
  );
};




const ACCEPT_FILE = '/*';

class SendMessageFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadAttachmentToAwsRequested: false,
      attachmentDeleteRequested: false,
      uploadedAttachmentsUrls: [],
      stopLoop: false,
      files: ['nice.pdf', 'verycool.jpg'],
      error: '',
    };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
    this.onAttachmentUpload = this.onAttachmentUpload.bind(this);
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  handleError(error){
    this.setState({error: error})
    setTimeout(() => this.setState({error: ''}), 5000)
  }
  
  async onAttachmentUpload(file) {
    if (file && file.name) {
      const updateduploadedAttachmentsUrls = [...this.state.uploadedAttachmentsUrls];
      const response = await handleFileUpload('upload-file-aws', {file})
      
      if(response?.data?.status !== 200){
        this.handleError('Unexpected error occured uploading file. Try file with different name or try after some time.')
        this.setState({ uploadAttachmentToAwsRequested: false });
        return
      }

      const { location, key } = response?.data || {};
      const currentDate = moment().format('MM-DD-YYYY hh:mm:ss');
      updateduploadedAttachmentsUrls.push({ link: location, date: currentDate, name: file.name, key });
      this.setState({ uploadedAttachmentsUrls: updateduploadedAttachmentsUrls, attachmentDeleteRequested: false, uploadAttachmentToAwsRequested: false });

    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        initialValues={{ attachmentsUrls: this.props.attachmentsUrls }}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            messagePlaceholder,
            handleSubmit,
            inProgress,
            sendMessageError,
            invalid,
            intl,
            form,
            formId,
            values,
            // clearAttachmentForm,
            // isReviewd,
            // isChatExpired,
            currentTransaction,
            sendGif,
            membersData,
            nonMemberData,
          } = formRenderProps;
          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const lastTransition = currentTransaction && currentTransaction.id && currentTransaction.attributes?.lastTransition;
          const submitDisabled = invalid || submitInProgress || lastTransition === TRANSITION_DECLINE_BRIEF;
          const fileUploadLabel = <img src={PinIcon} alt="Attach Files" />;
          const [openEmoji, setOpenEmoji] = useState(false);
          const [openGif, setOpenGif] = useState(false);

          // const RequiredMessage = intl.formatMessage({
          //   id: 'SendMessageForm.requiredMessage',
          // });
          // const MessageRequired = validators.required(RequiredMessage);

          if (this.state.uploadedAttachmentsUrls && this.state.uploadedAttachmentsUrls.length) {
            values['attachmentsUrls'] = this.state.uploadedAttachmentsUrls;
          } else if (this.state.attachmentDeleteRequested) {
            values['attachmentsUrls'] = this.state.uploadedAttachmentsUrls;
          } else if (values['attachmentsUrls'] && values['attachmentsUrls'].length) {
            this.setState({ uploadedAttachmentsUrls: values['attachmentsUrls'] });
          }

          const onRemoveFileLinks = async (index, file) => {
            if (index > -1) {
              await removeFileFromAzure({key: file.key ?? file.name})
              this.state.uploadedAttachmentsUrls.splice(index, 1)
              this.setState({ uploadedAttachmentsUrls: this.state.uploadedAttachmentsUrls });
            }
          };

          const handleEmoji = (e) => {
            const newText = values.message || '';
            form.change('message', newText.concat(e.native));
            setOpenEmoji(false);
            // const inputField = document.getElementById(formId ? `${formId}.message` : 'message');
            // console.log(inputField, 'inputField');
            // inputField.focus();
          }

          const handleGif = (gif) => {
            const url = gif;
            form.change('message', url);
            handleSubmit()
            // sendGif({ message: url }, form)
            setOpenGif(false)
          }

          return (
            <>
              {
                [TRANSITION_BRIEF_DECLINE_PROPOSAL,
                  TRANSITION_DECLINE_BRIEF,
                  TRANSITION_BRIEF_EXPIRE_PROPOSAL,
                  TRANSITION_DECLINE_PROPOSAL].includes(lastTransition) ?
                  <></> :
                  <Form className={classes}
                    id="SendMessageForm"
                    onSubmit={values => {
                      handleSubmit(values, form);
                      this.setState({ uploadedAttachmentsUrls: [] });
                      form.change('attachmentsUrls', []);
                    }}
                  // onKeyDown={e => {
                  //   if (!e.shiftKey && e.keyCode === 13 && values.message) {
                  //     e.preventDefault();
                  //     return handleSubmit(values, form);
                  //   }
                  // }}
                  >
                    {openEmoji &&
                      <OutsideClickHandler onOutsideClick={() => setOpenEmoji(false)}>
                        <Picker data={data} onEmojiSelect={handleEmoji} />
                      </OutsideClickHandler>
                    }

                    <div className={css.chatBottomMessageBar}>
                      <div className={css.textareaBox}>
                        <Mentions
                          autoFocus
                          rows={3}
                          autoSize
                          placement='top'
                          value={values.message}
                          onChange={text => form.change('message', text)}
                          onPressEnter={e => {
                            if (!e.shiftKey && values.message) {
                              e.preventDefault()
                              return handleSubmit(values, form);
                            }
                          }}
                        >
                          <h5 className='mentionHeading'>Members</h5>
                          {membersData?.length && membersData?.map(member => (
                            <Option value={member?.fullName?.replace(/\s+/g, "_")?.toLowerCase()} key={member.email}>
                              <div className='row'>
                                {member?.profileImage ? (
                                  <img src={member.profileImage} />
                                ) : (
                                  <span>{member?.fullName?.match(/(\b\S)?/g).join('').toUpperCase()}</span>
                                )}
                                <div className='mentionRow'>
                                  <h3>{member?.fullName}</h3>
                                  <label>{member?.email}</label>
                                </div>
                              </div>
                            </Option>
                          ))}
                          {nonMemberData?.map((member, index) => (
                            <Option value={member?.fullName?.replace('@', '')} key={member.email}>
                              <div className={classNames(index === 0 && 'divider')}></div>
                              <div className='row'>
                                <div className='mentionRow'>
                                  <h3>{member?.fullName}</h3>
                                  <label>{member?.email}</label>
                                </div>
                              </div>
                            </Option>
                          ))}
                        </Mentions>
                        {/* <FieldTextInput
                          inputRootClass={css.textarea}
                          type="textarea"
                          id={formId ? `${formId}.message` : 'message'}
                          name="message"
                          placeholder={messagePlaceholder}
                        // validate={MessageRequired}
                        // onFocus={() => setOpenEmoji(false)}
                        // onBlur={this.handleBlur}
                        /> */}
                        {openGif &&
                          <div className={css.gifPicker}>
                            <TenorGifViewer handleGif={handleGif} onCloseGifModal={() => setOpenGif(false)} />
                          </div>
                        }
                      </div>
                      <div className={css.submitContainer}>
                        <SecondaryButton
                          className={css.sendMessage}
                          rootClassName={css.submitButton}
                          inProgress={submitInProgress}
                          disabled={submitDisabled}
                          onFocus={this.handleFocus}
                          onBlur={this.handleBlur}
                          type="submit"
                        >
                          <IconSendMessage />
                          <FormattedMessage id="SendMessageForm.sendMessage" />
                        </SecondaryButton>
                      </div>
                    </div>

                    <div className={css.actionButton}>
                      {this.state.uploadAttachmentToAwsRequested ? (
                        <div className={css.loader}>
                          <IconSpinner strokeColor='orange' />
                        </div>
                      ) : (
                        this.state.uploadedAttachmentsUrls && this.state.uploadedAttachmentsUrls.map((item, index) =>
                          <div className={css.attachmentFile} key={uuidv4()}>
                            {item && item.name}
                            <span
                              onClick={e => {
                                e.stopPropagation();
                                onRemoveFileLinks(index, item);
                              }}
                            >
                              <IconClose />
                            </span>
                          </div>
                        )
                      )}
                      <div className={css.iconContainer}>
                        <span onClick={() => setOpenEmoji(!openEmoji)}>
                          <img src={emojiIcon} className={css.emojiIcon} />
                        </span>
                        <div className={css.chat}>
                          <Field
                            label={fileUploadLabel}
                            id="fileName"
                            name="fileName"
                            accept={ACCEPT_FILE}
                            form={null}
                            type="file"
                          >
                            {fieldprops => {
                              const {
                                accept,
                                input,
                                label,
                                meta,
                                disabled: fieldDisabled,
                              } = fieldprops;
                              const { name, type } = input;
                              const onChange = e => {
                                const file = e.target.files[0];
                                if (file && file.name) {
                                  this.setState({ uploadAttachmentToAwsRequested: true, stopLoop: false });
                                  this.onAttachmentUpload(file);
                                  e.target.value = null;
                                }
                              };

                              const inputProps = { accept, id: name, name, onChange, type };
                              return (<>
                                {fieldDisabled ? null : (
                                  <div className={classNames(css.pin, this.state?.uploadedAttachmentsUrls?.length === 1 && css.pointerEvents)}>
                                    <input {...inputProps} className={css.addImageInput} />
                                  </div>
                                )}
                              </>);
                            }}
                          </Field>
                        </div>
                        <span onClick={() => setOpenGif(!openGif)}>
                          <img src={gifIcon} className={css.emojiIcon} />
                        </span>
                      </div>
                      <div className={css.bottomRight}>
                        <div className={css.chatTooltip}>
                          Tips
                          <div className={css.tooltip}>
                            <p>If a user is online, they will have a green dot on their profile image towards the right of the chat window. Similarly, a user who is offline has a red dot. </p>
                            <p>If you <b>@</b> a user, they will get an email notification about new messages in their chat. You can also use <b>@everyone</b> (notify everyone), <b>@nothere</b> (notify offline users), and <b>@here</b> (notify online users). </p>
                            <p>To avoid email overload, no notifications are sent to users unless you <b>@</b> them. </p>
                            <p>Happy chatting!</p>
                          </div>
                        </div>
                        <SecondaryButton
                          className={css.mobileSendMessage}
                          rootClassName={css.submitButton}
                          inProgress={submitInProgress}
                          disabled={submitDisabled}
                          onFocus={this.handleFocus}
                          onBlur={this.handleBlur}
                          type="submit"
                        >
                          <IconSendMessage />
                          <FormattedMessage id="SendMessageForm.sendMessage" />
                        </SecondaryButton>
                      </div>
                    </div>
                    {this.state.error && <p className={css.error}>{this.state.error}</p>}
                    {sendMessageError ? (
                      <div className={css.errorContainer}>
                        <p className={css.error}>
                          <FormattedMessage id="SendMessageForm.sendFailed" />
                        </p>
                      </div>
                    ) : null}
                  </Form>
              }
            </>
          );
        }}
      />
    );
  }
}

SendMessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

SendMessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  // message: string.isRequired,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SendMessageForm = compose(injectIntl)(SendMessageFormComponent);

SendMessageForm.displayName = 'SendMessageForm';

export default SendMessageForm;
