import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
// These relative imports need to point to correct directories

import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { Form,  FieldTextInput, SecondaryButton, PrimaryButton, IconCard, CaptureRunNameField } from '../../components';
import css from './OpenAITranscriptionForm.module.css';
import FileView from '../../components/FileView/FileView';
import classNames from 'classnames';

const ACCEPT_FILE = 'audio/mpeg, audio/mp3, audio/mpga, audio/mp4, audio/m4a, audio/wav, audio/webm, video/mp4, .m4a';
const FILE_SIZE_LIMIT = 2 * 1024 * 1024 * 1024; // 2 GB in bytes
const validFileFormat = ['mp3', 'mp4', 'mpeg', 'mpga', 'm4a', 'wav', 'webm'];

export const OpenAITranscriptionForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          disabled,
          handleSubmit,
          intl,
          values,
          form,
          openaiRequest,
          initialText,
        } = formRenderProps;

        const [error, setError]= useState('');

        useEffect(() => {
          form.change('file', values?.file);

          if (initialText?.content) {
            // form.reset();
          }
        }, [values?.file, initialText]);

        function handleError(errorMessage){
          setError(errorMessage)
          setTimeout(() => setError(''), 3000)
        }

        const heading = intl.formatMessage({ id: "OpenAITransacriptionForm.heading"});
        const attachmentDescription = intl.formatMessage({ id: "OpenAITransacriptionForm.attachmentDescription"});
        const inputLabel = intl.formatMessage({ id: "OpenAITransacriptionForm.inputLabel"});
        const inputPlaceholder = intl.formatMessage({ id: "OpenAITransacriptionForm.inputPlaceholder"});
        const attachmentButtonText = intl.formatMessage({ id: "OpenAITransacriptionForm.attachmentButtonText"});
        const submitButtonText = intl.formatMessage({ id: "OpenAITransacriptionForm.submitButtonText"});
        const { file, step = 1 } = values || {};

        const submitDisabled = !file?.name;
       
        const attachmentLabel = intl.formatMessage({
          id: 'OpenAITransacriptionForm.attachmentLabel',
        });
     
        return (
          <Form className={css.transcriptionForm} onSubmit={(e)=>e.preventDefault()}>
            <div className={css.container}>
              <div className={css.briefGenerator}>
                <span className={css.generatorTitle}>{heading}</span>
              </div>
              <CaptureRunNameField step={step} form={form} values={values}>
                <div>
                  <div>
                    <FieldTextInput
                      className={css.inputForm}
                      type="textarea"
                      id={'input'}
                      name="input"
                      placeholder={inputPlaceholder}
                      label={inputLabel}
                    />
                  </div>
                  <div className={css.supportText}>
                    <label>{attachmentLabel}</label>
                  </div>
                  <div className={css.attachment}>
                    {file?.name ? (
                      <FileView file={file}>
                        <span className={css.close} onClick={() => form.change('file', null)}>
                          <IconCard brand="cross" />
                
                        </span>
                      </FileView>
                    ) : (
                      <Field id="file" name="file" accept={ACCEPT_FILE} type="file">
                        {fieldprops => {
                          const { accept, input, disabled: fieldDisabled } = fieldprops;
                          const { name, type } = input;
                          const onChange = e => {
                            const file = e.target.files[0];

                            const extension = file?.name?.split('.').at(-1);

                            if(!validFileFormat.includes(extension)){
                              handleError('Only Audio and Video files accepted.')
                              return
                            }
                            if (file.size > FILE_SIZE_LIMIT) {
                              handleError('Please choose file less than or equal to 25mb.')
                              return
                            }
                            form.change('file', file);
                          };

                          const inputProps = { accept, id: name, name, onChange, type };
                          return (
                            <div>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label type="button" htmlFor={name} className={css.attachmentLabel}>
                                <IconCard brand="upload" />
                                <span className={css.dragDrop}>
                                  <FormattedMessage id="ZohoSign.dragAndDropUpload" />
                                </span>
                                <span className={css.docType}>{attachmentDescription}</span>
                              </label>
                              {error && <p className={css.errorMessage}>{error}</p>}
                            </div>
                          );
                        }}
                      </Field>
                    )}
                  </div>
                  <div className={css.actionButtons}>
                    <SecondaryButton
                      type="button"
                      className={css.resetButton}
                      onClick={() => form.reset()}
                      disabled={openaiRequest}
                    >
                      <FormattedMessage id="OpenAIContractForm.resetButtonText" />
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      onClick={(e) => {
                        if(!values?.file?.name){
                          e.preventDefault()
                          !error && handleError("Please choose a file first")
                        }else{
                          handleSubmit(values)
                        }
                      }}
                      className={classNames(submitDisabled ? css.disabledSubmitButton : css.submitButton )}
                      inProgress={openaiRequest}
                    >
                      {submitButtonText}
                    </PrimaryButton>
                  </div>
                </div>
              </CaptureRunNameField>
            </div>
          </Form>
        );
      }}
    />
  );
};

OpenAITranscriptionForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  //   updateInProgress: bool.isRequired,
};

export default compose(injectIntl)(OpenAITranscriptionForm);
