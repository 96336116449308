import React, { Component } from 'react';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { AddFileLinks, Form, ValidationError, PrimaryButton, IconSpinner } from '../../components';
import css from './AttachmentForm.module.css';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { apiBaseUrl, handleFileUpload, removeFileFromAzure } from '../../util/api';
import axios from 'axios';
import { connect } from 'react-redux';

const ACCEPT_FILE = '.doc,.docx,application/msword,application/pdf,video/*,.pptx,.ppt,.txt,.xlsx,.xls';

export class AttachmentFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadAttachmentToAwsRequested: false,
      attachmentDeleteRequested: false,
      uploadedAttachmentsUrls: this.props.attachmentsUrls,
      stopLoop: false,
      files: ['nice.pdf', 'verycool.jpg'],
      imageUploadRequested: false,
      fileState: null,
      error: '',
    };

    this.onAttachmentUpload = this.onAttachmentUpload.bind(this);
    this.onRemoveFileLinks = this.onRemoveFileLinks.bind(this);
  }

  handleError(error){
    this.setState({error: error})
    setTimeout(() => this.setState({error: ''}), 5000)
  }

  async onAttachmentUpload(file, handleSubmit) {
    if (file && file.name) {
      const updateduploadedAttachmentsUrls = [...this.state.uploadedAttachmentsUrls];
      const response = await handleFileUpload('upload-file-aws', {file})
      
      if(response?.data?.status !== 200){
        this.handleError('Unexpected error occured uploading file. Try file with different name or try after some time.')
        this.setState({ uploadAttachmentToAwsRequested: false });
        return
      }

      const { location,key } = response?.data || {};
      const currentDate = moment().format('MM-DD-YYYY hh:mm:ss');
      updateduploadedAttachmentsUrls.push({
        link: location,
        date: currentDate,
        name: file.name,
        id: uuidv4(),
        size: file.size,
        key
      });
      this.setState({
        uploadedAttachmentsUrls: updateduploadedAttachmentsUrls,
        attachmentDeleteRequested: false,
        uploadAttachmentToAwsRequested: false,
      });
      handleSubmit({attachmentsUrls: updateduploadedAttachmentsUrls})
    }
  }

  async onRemoveFileLinks (file, handleSubmit) {
    const updatedAttachmentUrls = [...this.state.uploadedAttachmentsUrls];
    await removeFileFromAzure({key: file.key ?? file.name})
    const index = updatedAttachmentUrls.findIndex(item => item.id === file?.id);
    updatedAttachmentUrls.splice(index, 1)
    this.setState({ uploadedAttachmentsUrls: updatedAttachmentUrls});
    handleSubmit({attachmentsUrls: updatedAttachmentUrls})
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        // initialValues={{ attachmentsUrls: this.props.attachmentsUrls }}
        render={formRenderProps => {
          const {
            form,
            handleSubmit,
            updateInProgress,
            // saveActionMsg = "Save",
            intl,
            updated,
            pristine,
            ready,
            className,
            values,
            submitButtonText,
          } = formRenderProps;
          const { uploadedAttachmentsUrls, fileState, error } = this.state;
          const attachmentUploadProgress = this.state.uploadAttachmentToAwsRequested;

          const fileUploadLabel = intl.formatMessage({
            id: 'AttachmentForm.chooseImage',
          });
          const documentsLabel = intl.formatMessage({
            id: 'AttachmentForm.documentsLabel',
          });
          const documentsPlaceholder = intl.formatMessage({
            id: 'EditListingAdditionalForm.documentsPlaceholder',
          });
          const fileUploadLimitMessage = intl.formatMessage({
            id: 'AttachmentForm.uploadLimitMessage',
          });

          const submitInProgress = updateInProgress;
          const submitReady = (updated && pristine) || ready;
          values['attachmentsUrls'] = uploadedAttachmentsUrls;
          
          return (
            <Form id="attachmentform" onSubmit={handleSubmit} className={className}>
              {/* <AddFileLinks
                className={css.imagesField}
                fileLinks={this.state.uploadedAttachmentsUrls}
                thumbnailClassName={css.fileLink}
                onRemoveFileLinks={this.onRemoveFileLinks}
              >
              </AddFileLinks> */}
              <div className={css.imagesField}>
                <label className={css.imageLabel}>{documentsLabel}</label>
                {uploadedAttachmentsUrls?.length >= 3 && (
                  <h3 className={css.fileUploadLimitMessage}>{fileUploadLimitMessage}</h3>
                )}
                {error && (
                  <h3 className={css.fileUploadLimitMessage}>{error}</h3>
                )}
                {uploadedAttachmentsUrls && uploadedAttachmentsUrls.length >= 3 ? null : (
                  <Field
                    label={documentsPlaceholder}
                    id="fileName"
                    name="fileName"
                    accept={ACCEPT_FILE}
                    form={null}
                    type="file"
                  >
                    {fieldprops => {
                      const { accept, input, label, meta, disabled: fieldDisabled } = fieldprops;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        this.setState({ fileState: file });
                        if (file && file.name && file.size < 10000000) {
                          this.setState({ uploadAttachmentToAwsRequested: true, stopLoop: false });
                          this.onAttachmentUpload(file, handleSubmit);
                          e.target.value = null;
                        }
                      };

                      const inputProps = { accept, id: name, name, onChange, type };
                      return (
                        <div className={css.addImageWrapper}>
                          <div className={css.aspectRatioWrapper}>
                            {fieldDisabled ? null : (
                              <input {...inputProps} className={css.addImageInput} />
                            )}
                            <label htmlFor={name} className={css.addImage}>
                              {attachmentUploadProgress ? <IconSpinner strokeColor ='#F6B430'/> : label}
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  </Field>
                )}
                {!!fileState && fileState.size > 10000000 && (
                  <div className={css.errorMessage}>
                    <FormattedMessage id="AttachmentForm.fileSizeExceededMessage" />
                  </div>
                )}
                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                />
              </div>

              <ul>
                {uploadedAttachmentsUrls?.length !== 0 &&
                  uploadedAttachmentsUrls?.map((item, index) => {
                    const extension = item?.link?.split('.').at(-1);
                    const videoFile = ['mp4', 'mov', 'webm'].includes(extension);
                    return(
                      <div key={item?.id} className={css.docBox}>
                        <li className={css.documentHeader}>
                          <span className={css.docName}>{item && item.name}</span>
                          <span 
                            className={css.closer}
                            onClick={() => this.onRemoveFileLinks(item, handleSubmit)}
                          >
                            <FormattedMessage id="ListingPage.closeTagInput"/>
                          </span>
                        </li>
                        <li className={css.videoBox}>
                          {videoFile ? (
                            <video src={item.link} loop autoPlay={true} muted className={css.video} />
                          ) : (
                            <iframe
                              className="doc"
                              src={`https://docs.google.com/gview?url=${item.link}&embedded=true`}
                            ></iframe>
                          )}
                        </li>
                      </div>
                    )
                  })
                }
              </ul>

              <Field
                component={props => {
                  const { input, meta } = props;
                  return (
                    <div className={css.imageRequiredWrapper}>
                      <input {...input} />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  );
                }}
                name="fileformat"
                type="hidden"
              />
              <div className={css.ButtonCenter}>
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  ready={submitReady}
                // disabled={uploadedAttachmentsUrls.length <= 0 || uploadedAttachmentsUrls.length > 3}
                >
                  {submitButtonText}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}
const mapStateToProps = state => {
  return {}
};

const AttachmentForm = compose(
  connect(mapStateToProps),
  injectIntl
)(AttachmentFormComponent);

export default AttachmentForm;
