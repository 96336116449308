import axios from "axios";
import { useState } from "react";
import { saveAs } from 'file-saver';
import moment from 'moment';
import { handleConvertHTMLtoPdf } from "../components/OpenAIAppsPanel/gigAppsHelperFunction";

export default function useDownloadContent(){
  const [fetchLoading, setFetchLoading] = useState({});

  async function downloadFetchContent(type, content){
    try{
      setFetchLoading({type, isLoading: true})
  
      const formData = new FormData();
      formData.append('type', type);
      formData.append('content', content);
      
      const response = await axios.post(`${process.env.REACT_APP_DJANGO_BACKEND_API}/convert_output`, 
        formData, 
        { headers: { 'Content-Type': 'multipart/form-data'} }
      );
  
      if(response.status === 200){
        //Convert buffer string into buffer
        const base64String = response?.data?.bufferString;
        const buffer = Buffer.from(base64String, 'base64'); 
  
        // Create a Blob object from the Buffer data
        const blob = new Blob([buffer]);
  
        // Downloading the file
        const date = new Date().valueOf();
        saveAs(blob, `GigAppsResponse-${date}.${type}`);
        setFetchLoading({})
        // updateOpenAIData({id})
      }
      else throw new Error(response)
    }
    catch(error){
      setFetchLoading({})
    }
  }

  async function downloadPdf(fileDetails, chatResponse, lastItem){
    setFetchLoading({type:'pdf', isLoading: true})
    
    const filesNameMarkdown = `**Date:** ${moment().format('LLL')}\n\n**Uploaded Files:**\n\n- ${fileDetails[0].filename}\n\n`;

    let content = chatResponse.map(({ question, answer }) => (
      `___You___\n\n${question}\n\n___Response___\n\n${answer}`
    )).join('\n\n___\n\n');
    content = content.replace(/<img([^>]+)width="(\d+(\.\d+)?)"([^>]+)height="(\d+(\.\d+)?)"/g, '<img$1style="width: $2px; height: $5px;"$4');

    const result = filesNameMarkdown + content + (lastItem.question ? `\n\n___\n\n___You___\n\n${lastItem.question}\n\n___Response___\n\n${lastItem.answer}` : '');

    const response = await handleConvertHTMLtoPdf({ content: result, isAnalyzeQuantDataApp: true });
    const bufferObject = response.data;
    const buffer = Buffer.from(bufferObject.data);
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const date = new Date().valueOf();
    saveAs(blob, `GigAppsResponse-${date}.pdf`);
    setFetchLoading({})
  }
     
  return [fetchLoading, downloadFetchContent, downloadPdf]
}