import React, { useState } from 'react';
import { compose } from 'redux';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  Form,
  IconCard,
  IconSpinner,
} from '../../components';
import css from './ModalApplicationForm.module.css';
import moment from 'moment';
import { required } from '../../util/validators';
import { currencyFeeTemp } from '../../marketplace-custom-config';
import { generateResume, handleFileUpload, removeFileFromAzure } from '../../util/api';
import FileView from '../../components/FileView/FileView';
import { ACCEPT_FILE } from '../../util/types';

const validFileFormat = ['pdf','doc','docx','ppt','pptx'];

const ModalApplicationForm = (props) => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          form,
          className,
          handleSubmit,
          intl,
          invalid,
          disabled,
          ready,
          values,
          onClose,
          inProgress,
          params,
          isSecondMent,
          listingId,
        } = formRenderProps;

        const [isLoading, setIsLoading]= useState(false);
        const [error, setError]= useState('');
        const [isResumeCreated, setIsResumeCreated] = useState(false);
        const classes = classNames(css.root, className);
        const submitDisabled = invalid || inProgress ||
          !(isSecondMent
            ? (values.expectedSalary && values.resume?.link)
            : (values.lastSalary && values.expectedSalary && values.resume?.link)
          );

        const reasonLabel = intl.formatMessage({ id: 'ModalApplicationForm.reasonLabel' });
        const reasonPlaceholderMessage = intl.formatMessage({ id: 'ModalApplicationForm.reasonPlaceholderMessage' });
        const reasonRequiredMessage = intl.formatMessage({ id: 'ModalApplicationForm.reasonRequiredMessage' });
        const lastSalaryLabel = intl.formatMessage({ id: 'ModalApplicationForm.lastSalaryLabel' });
        const expectedCtcLabel = intl.formatMessage({ id: 'ModalApplicationForm.expectedCtcLabel' });
        const expectedSalaryLabel = intl.formatMessage({ id: 'ModalApplicationForm.expectedSalaryLabel' });
        const submitLabel = intl.formatMessage({ id: 'ModalProposalForm.submit' });
        const cancelLabel = intl.formatMessage({ id: 'ModalProposalForm.cancel' });

        async function handleDocumentUpload(file) {
          if (file && file.name) {
            const response = await handleFileUpload('upload-file-aws', {file})

            if(response?.data?.status !== 200){
              handleError('Unexpected error occured uploading file. Try file with different name or try after some time.')
              setIsLoading(false)
              return
            }

            const { location,key } = response?.data || {};
            const attachment = {
              link: location,
              date: moment().format('MM-DD-YYYY hh:mm:ss'),
              name: file.name,
              size: file.size,
              key
            }
            if (location) {
              form.change('resume', attachment)
              setIsResumeCreated(false)
              setIsLoading(false)
            }
          }
        }

        function handleMoneyFormat(value, key) {
          const modifiedValue = value.toLocaleString("en-US")
          if (Number.isInteger(value)) form.change(key, modifiedValue)
          if (value === 0) form.change(key, '')
        }

        async function handleGenerateResume() {
          try {
            setIsResumeCreated(true)
            const response = await generateResume({ listingId: listingId})
            if (response?.data?.data) {
              const buffer = Buffer.from(response.data.data, 'base64')
              const file = new File([buffer], `${params?.name}_resume.pdf`, { type: 'application/pdf' })

              handleDocumentUpload(file)
            }
          } catch (error) {
            setIsResumeCreated(false)
          }
        }

        function handleError(errorMessage){
          setError(errorMessage)
          setTimeout(() => setError(''), 5000)
        }

        return (
          <Form className={classes} onSubmit={e => handleSubmit(e)}>
            <FieldTextInput
              id="reason"
              name="reason"
              className={css.description}
              type="textarea"
              label={reasonLabel}
              placeholder={reasonPlaceholderMessage}
              validate={required(reasonRequiredMessage)}
            />
            <div className={css.attachmentLabel}><FormattedMessage id="ModalApplicationForm.attachmentLabel" /></div>
            {!values?.resume?.name ? (
              <div className={css.inputSection}>
                <Field
                  id="resume"
                  name="resume"
                  accept={ACCEPT_FILE}
                  type="file"
                  disabled={isLoading}
                >
                  {fieldprops => {
                    const { accept, input } = fieldprops;
                    const { name, type } = input;
                    const onChange = async (e) => {
                      const file = e.target.files[0];
                      const extension = file?.name?.split('.').at(-1);

                      if(!validFileFormat.includes(extension)){
                        handleError('Only PDF, DOC or PPT formats accepted.')
                        return
                      }
                      setIsLoading(true)
                      handleDocumentUpload(file)
                    };
                    const inputProps = { accept, id: name, name, onChange, type };
                    return <input {...inputProps} className={css.addImageInput} />
                  }}
                </Field>
                <label htmlFor="resume" className={css.inputLabel}>
                  {isLoading ? <IconSpinner strokeColor='#0B96F5' /> : <IconCard brand="upload" />}
                  <span className={css.dragDrop}><FormattedMessage id="ZohoSign.dragAndDropUpload" /></span>
                  <span className={css.docType}>
                    <FormattedMessage id="JobListing.documentAllowList" />
                  </span>
                </label>
                {error && <p className={css.errorMessage}>{error}</p>}
                {/* <div className={css.createResumeLink}>
                  <span onClick={() => handleGenerateResume()}>
                    {isResumeCreated
                      ? <IconSpinner strokeColor='#0B96F5' />
                      : 'Create PDF resume '
                    }
                  </span>
                  of existing profile on InsightGig
                </div> */}
              </div>
            ) : (
              <FileView file={values?.resume}>
                <span className={css.removeFile} onClick={async () => {
                  await removeFileFromAzure({ key: values?.resume.key })
                  form.change('resume', {})
                }}>
                  <FormattedMessage id="ZohoSignForm.remove" />
                </span>
              </FileView>
            )}
            <label className={css.selectLabel}>{lastSalaryLabel}</label>
            <div className={classNames(css.inputsWrapper, isSecondMent && css.disable)}>
              <FieldSelect
                id='lastSalaryCurrency'
                name='lastSalaryCurrency'
                className={css.currencySelector}
              >
                {currencyFeeTemp.map(({ sym, key }) => (
                  <option key={key} value={key}>{` ${sym} - ${key}`}</option>
                ))}
              </FieldSelect>
              <FieldTextInput
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/\,/g, ''))
                  handleMoneyFormat(value, 'lastSalary')
                }}
                type="text"
                id='lastSalary'
                name='lastSalary'
              // placeholder={'0.00'}
              />
            </div>
            <label className={css.selectLabel}>{isSecondMent ? expectedSalaryLabel : expectedCtcLabel}</label>
            <div className={css.inputsWrapper}>
              <FieldSelect
                id='expectedSalaryCurrency'
                name='expectedSalaryCurrency'
                className={css.currencySelector}
              >
                {currencyFeeTemp.map(({ sym, key }) => (
                  <option key={key} value={key}>{` ${sym} - ${key}`}</option>
                ))}
              </FieldSelect>
              <FieldTextInput
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/\,/g, ''))
                  handleMoneyFormat(value, 'expectedSalary')
                }}
                type="text"
                id='expectedSalary'
                name='expectedSalary'
              // placeholder={'0.00'}
              />
            </div>
            <div className={css.buttonWrapper}>
              <p className={css.cancelButton} onClick={onClose} disabled={submitDisabled}>
                {cancelLabel}
              </p>
              <Button
                className={css.submitButton}
                type="submit"
                disabled={submitDisabled} 
                inProgress={inProgress}
              >
                {submitLabel}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  )
}

export default compose(injectIntl)(ModalApplicationForm);
