import React, { useEffect, useRef, useState } from 'react'
import useDownloadContent from '../../hooks/useDownloadContent';
import { FormattedMessage } from 'react-intl';
import { IconSpinner, LinesLoader, LoadingDots, Modal, NamedLink, PrimaryButton } from '../../components';
import {  loadViewerComponent } from '../../components/OpenAIAppsPanel/gigAppsHelperFunction';
import classNames from 'classnames';
import { deleteThreadMessages, extendQueryLinkExpiry, fetchQueryReportData, generateNewThread } from '../../util/api';
import css from './OpenAIAnalyzeRawQuantitativeDataForm.module.css';
import moment from 'moment';
import FileView from '../../components/FileView/FileView';
import { handleStopThreadStreamResponse, handleStreamResponse, handleThreadResponse } from './ThreadResponse';
import "./AnalyzeRawQuantitativeData.css"
// import useQueryTimer, { SESSION_THREASHOLD_IN_SECONDS } from '../../hooks/useQueryTimer';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import axios from 'axios';

const Loader = ({ type, id, fetchLoading }) => {
  if (fetchLoading?.type === type && !!fetchLoading?.isLoading) {
    return <IconSpinner strokeColor='orange' />
  }
  else {
    return <FormattedMessage id={id} />
  }
}

const NO_ANSWER_TEXT= "Sorry, I am unable to provide a response to your query. Please try again."

function QueryAnalyzeRawQuantitativeDataComponent(props) {
  const { form, params, error, setError, appRoute, fileExtension, manageDisableScrolling, transactionThreadId, history } = props;

  const [question, setQuestion] = useState('');
  const [lastItem, setLastItem] = useState({});
  const [chatResponse, setChatResponse] = useState([]);
  const [showInput, setShowInput] = useState(!chatResponse?.length);
  const chatContainerRef = useRef(null);
  const viewerRef = useRef(null);
  const answerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoading, downloadFetchContent, downloadPdf] = useDownloadContent();
  const [contentLoader, setContentLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    const refreshSandboxSession = async()=>{
      const e2bSessionId = sessionStorage.getItem('e2bSessionId')
      if(e2bSessionId){
        const url = `${process.env.REACT_APP_DJANGO_BACKEND_API}/refresh_sandbox_session`
        
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ e2bSessionId }),
        });
      }
    }
    const interval = setInterval(refreshSandboxSession, 30000)

    return () => clearInterval(interval)
  },[])
  
  const isTransaction = transactionThreadId ? true : false;
  const id = isTransaction ? params?.fileId : params?.id;
  const [sessionData, setSessionData] = useState({});
  const {fileDetails = [], queryThreadId, contextFileId="", contextSheetName="", datasetFileId="", datasetSheetName="", createdAt, expiresAt, email="" } = sessionData || {};
  const threadId = queryThreadId || transactionThreadId;

  const isSessionExpired = expiresAt === 'expired' || moment() > moment(expiresAt);
  const linkExpiryDate = moment(expiresAt).format('LL');
  const expiryDatePlus15Days = moment(expiresAt).add(15, 'days');
  const createdAtDatePlus90Days = moment(createdAt).add(90, 'days');

  // Handle session timer
  // const [setInactivityTimer] = useQueryTimer(id, isTransaction, fetchData);
  
  async function handleCreateE2Bsession(data){
    const isSessionAlreadyExist = sessionStorage.getItem('e2bSessionId');
    if(isSessionAlreadyExist){
      const response = await axios.get(`${process.env.REACT_APP_DJANGO_BACKEND_API}/check_existing_e2b_session`);

      if(response?.data.status === 'success'){
        // Return if current saved session if active
        if(response.data.sessions.includes(isSessionAlreadyExist)) return true
      }
    }
    // return true
    try{
      const url = `${process.env.REACT_APP_DJANGO_BACKEND_API}/create_e2b_session`;
      const response = await axios.post(url, 
        {
          contextLocation: data?.contextLocation,
          datasetLocation: data?.datasetLocation
        },
        {
          'headers': {
            'Content-Type' : 'application/json' 
          }
        }
      )
      
      if(response?.data.status === 'success'){
        sessionStorage.setItem('e2bSessionId', response?.data.session)
        sessionStorage.setItem('sessionStartTime', new Date().valueOf())
        return true
      }
      else throw Error(response)
    }
    catch(error){
      console.log(error, '---error creating session---')
      return false
    }
  }

  function handleError(message) {
    setError(message)
    setTimeout(() => setError(false), 3000)
  }

  async function fetchData() {
    try{
      setContentLoader(true)

      // Fetch files from MongoDB
      const response = await fetchQueryReportData({ id, isTransaction });

      if(response.status === 'notfound'){
        setSessionData({
          fileDetails: [],
          createdAt: null,
          expiresAt: 'expired'
        })
        return 
      }

      const {data, createdAt, expiresAt, email} = response?.data || {};
      const messages = data?.queryData || [];

      if(!data?.contextLocation && !data?.datasetLocation) throw new Error('Dataset files not present!')
      // Create eb2 session and upload content & dataset files
      const e2bSession = await handleCreateE2Bsession(data)
      if(!e2bSession) throw new Error('Error creating session. Please try after some time.')

      // const currentTimeInUTC = moment().utc();
      // const secPassedSinceLastUpdated = currentTimeInUTC.diff(moment(updatedAt), 'seconds');

      // Generate new thread if last updated time exceeds one hour threshold
      // if(secPassedSinceLastUpdated >= SESSION_THREASHOLD_IN_SECONDS){
      //   generateNewThread({sessionId: id, transaction: isTransaction}).then(res => fetchData())
      // }else{
      //   setInactivityTimer(SESSION_THREASHOLD_IN_SECONDS-secPassedSinceLastUpdated)
      // }

      const filteredMessages = messages.map(item=>{
        return {
          question: item?.question,
          // answer: item?.answer?.replace(/!\[.*?\]\(.*?\)/g, '')
          answer: item?.answer
        }
      })
      setChatResponse(filteredMessages);
      
      let fileDetails = []
      // fileDetails.push({filename: data.openAIfile.name, bytes: data.openAIfile.size, created_at: moment(createdAt).unix()});
      fileDetails.push({filename: data.xlsxFile.name, bytes: data.xlsxFile.size, created_at: moment(createdAt).unix()});

      const fileName = data.xlsxFile.name;
      form.change('fileExtension', fileName.split(".")[fileName.split(".").length - 1]);
      
      setSessionData({
        email,
        queryThreadId: data?.threadId,
        fileDetails,
        contextFileId: data?.contextFileId,
        contextSheetName:  data?.contextFileSheet,
        datasetFileId: data?.datasetFileId,
        datasetSheetName: data?.datasetSheetName,
        createdAt, expiresAt
      })
      setContentLoader(false)
    }
    catch(error){
      handleError(error)
    }
  }

  useEffect(() => {
    try {
      const script = loadViewerComponent(viewerRef, answerRef);

      // Fetch session data on load
      fetchData();

      return () => {
        // Cleanup: Remove the script when the component unmounts
        document.body.removeChild(script);
      };
    } catch (error) {
      // console.log(error);
    }
  }, []);

  async function handleDocDownload(type) {
    const filesNameMarkdown = `**Date:** ${moment().format('LLL')}\n\n**Uploaded Files:**\n\n- ${fileDetails[0].filename}\n\n- ${fileDetails[1].filename}\n\n`;

    const content = chatResponse.map(({ question, answer }) => (
      `___You___\n\n${question}\n\n___Response___\n\n${answer}`
    )).join('\n\n___\n\n');

    const result = filesNameMarkdown + content + (lastItem.question ? `\n\n___\n\n___You___\n\n${lastItem.question}\n\n___Response___\n\n${lastItem.answer}` : '');
    downloadFetchContent(type, result)
  }

  const handleQueryQuestion = () => {
    if (isLoading) return;
    try {
      if(lastItem.question){
        setChatResponse([...chatResponse, lastItem])
      }
      setIsLoading(true);
      setQuestion('');
      // setInactivityTimer(SESSION_THREASHOLD_IN_SECONDS)

      // const input = (chatResponse.length === 0 && !lastItem.question)
      //   ? `The files are provided in the .${fileExtension} format. Read the sheets accordingly. ${question}`
      //   : question;

      setLastItem({ question: question,  answer: `<div class='quantAppQueryLoader'></div>`});
      setShowInput(false);
  
      // handleThreadResponse({
      //   id,
      //   email, 
      //   threadId,
      //   isTransaction,
      //   setIsLoading,
      //   handleError,
      //   setLastItem,
      //   question: input,
      //   type: appRoute,
      //   displayQuestion: question,
      //   datasetSheetName,
      //   datasetFileId,
      //   datasetFileName: fileDetails?.[1]?.filename,
      //   contextFileId,
      //   contextSheetName,
      //   contextFileName: fileDetails?.[0]?.filename,
      //   fileExtension
      // });
      if(sessionStorage.getItem('e2bSessionId')){
        const earlierMessages  = [...chatResponse]?.slice(-5) // Get last 5 messages
        if(lastItem.question){
          earlierMessages.push(lastItem)
        }
        handleStreamResponse({
          query: question, 
          e2bSessionId: sessionStorage.getItem('e2bSessionId'),
          sessionId : id,
          earlierMessages,
          email,
          setIsLoading,
          setLastItem
        })
      }
    } catch (err) {
      console.log(err, 'Error generating stream response!');
      handleError(err);
    }
  };

  useEffect(() => {
    try {
      const content = chatResponse.map(({ question, answer }) => {
        // const sanitizedAnswer = answer ? answer?.replace(/!\[.*?\]\(.*?\)/g, '')?.replace(">", "> \n") : NO_ANSWER_TEXT;
        const sanitizedAnswer = answer ? answer : NO_ANSWER_TEXT;
        return `___You___\n${question}\n\n___Response___\n${sanitizedAnswer}`
      }).join('\n___\n');

      if (viewerRef.current && answerRef.current) {
        answerRef.current.setMarkdown("", false);
        viewerRef.current.setMarkdown(content, false);
      }

      //Scroll the chat container to bottom
      const lastItemIndex = chatResponse?.length - 1;
      const data = chatResponse[lastItemIndex]?.answer;
      
      if (chatContainerRef.current && ((data?.length > 0) || data?.includes('<div'))) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
    } catch (e) {
      console.log(e, "chatResponse")
    }
  }, [chatResponse])
  
  useEffect(() => {
    try {
      if(lastItem.question){
        const sanitizedAnswer = lastItem.answer;
        // const sanitizedAnswer = lastItem.answer.replace(/!\[.*?\]\(.*?\)/g, '').replace(">", "> \n");
        const content = `___You___\n${lastItem?.question}\n\n___Response___\n${sanitizedAnswer}`

        answerRef.current.setMarkdown(content, false);

        if (lastItem?.answer?.length % 10 === 0 || lastItem?.answer.includes('<div')) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
      }
    } catch (e) {
      // console.log(e);
    }
  }, [lastItem]);
    
  //Handle and show Conversation pills transition
  const stopStream = () => {
    handleStopThreadStreamResponse(threadId)
    setIsLoading(false)
  }

  const deleteMessages = async(e) => {
    e.preventDefault()
    setDeleteModal('loading')
    const response = await deleteThreadMessages({threadId, id, isTransaction })
    if(response.data){
      setDeleteModal(false)
      setChatResponse([])
      setLastItem({})
      setShowInput(true)
    }
  }

  return (
    <div className={css.queryContainer}>
      <div className={css.briefGenerator}>
        <span className={css.generatorTitle}>
          <FormattedMessage id={"OpenAIAnalyzeRawDataForm.heading"} />
        </span>
      </div>
      <div className={css.files}>
        <label><FormattedMessage id='OpenAIQueryReportPage.uploadedFilesLabel' /></label>
        {!isSessionExpired && fileDetails.length > 0 ? (
          fileDetails.map((file, index) => (
            <React.Fragment key={index}>
              <FileView file={{ name: file?.filename, link: '', size: file.bytes, createdAt: file.created_at }} className={css.fileView} />
            </React.Fragment> 
          ))
        ) : (
          <LinesLoader />
        )}
      </div>
      {(!!chatResponse?.length || (lastItem.answer)) && !isLoading && (
        <div className={css.exportButtons}>
          <button type='button' onClick={(e) => handleDocDownload('docx')}>
            <Loader type='docx' id="TextEditor.exportDocButton" fetchLoading={fetchLoading}/>
          </button>
          <button type='button' onClick={(e) => downloadPdf(fileDetails, chatResponse, lastItem)}>
            <Loader type='pdf' id="TextEditor.exportPdfButton" fetchLoading={fetchLoading}/>
          </button>
          <button onClick={(e) => {
            e.preventDefault()
            setDeleteModal(true)
          }}>
            Delete messages
          </button>
        </div>
      )}
      <div className={css.container} ref={chatContainerRef}>
        <div className={classNames(css.fontStyling, !chatResponse?.length && css.chat)}>
          {contentLoader && <div className={css.contentLoader}><div className={css.loader} style={{height: "100%"}}></div></div>}
          <div ref={viewerRef} id="viewer"></div>
          <div ref={answerRef} id="answer"></div>
        </div>
      </div>
      {isLoading && (
        <div className={css.stopButton} onClick={stopStream}>
          <div><span></span></div>
        </div>
      )}
      {!showInput ? (chatResponse.length>0 || lastItem.answer) && (
        <div>
          {!isLoading && (
            <div className={css.chatButton}>
              <button
                type='button'
                className={css.clearButton}
                onClick={() => {
                  setChatResponse([])
                  setLastItem({})
                  setShowInput(true)
                }}
              >
                Clear
              </button>
              <PrimaryButton
                type='button'
                className={css.askQuestionButton}
                onClick={() => {
                  setShowInput(true)
                }}
                disabled={isLoading || showInput}
              >
                Chat with Assistant
              </PrimaryButton>
            </div>
          )}
        </div>
      ) : (
        <>
          {error && <p className={css.error}><FormattedMessage id='OpenAiAppsPanel.errorMessage' /></p>}
          <div className={css.questionInputWrapper}>
            <label><FormattedMessage id='OpenAIQueryReportPage.questionLabel' /></label>
            <div className={css.questionBar}>
              <textarea
                className={classNames(css.textarea, isLoading && css.disableInput)}
                id='question'
                name='question'
                disabled={isLoading || isSessionExpired}
                value={question}
                onChange={e => {
                  const textarea = document.getElementById('question');

                  textarea.addEventListener('input', function() {
                    if(question === '') 
                    this.style.minHeight = 'unset';
                    this.style.height = '100px'; 
                    if(this.scrollHeight < 200){
                      this.style.overflowY = 'auto';
                      this.style.minHeight = this.scrollHeight + 'px';
                    }else{
                      this.style.minHeight = '200px';
                      this.style.overflowY = 'scroll';
                    }
                  });
                  setQuestion(e.target.value)
                }}
                onKeyPress={e => e.key === 'Enter' && handleQueryQuestion()}
                row={1}
              ></textarea>
              <PrimaryButton
                type='button'
                className={(css.submitButton,css.queryButtn)}
                disabled={!question || contentLoader || isSessionExpired}
                onClick={() => handleQueryQuestion()}
              >
                {!isLoading
                  ? <FormattedMessage id="OpenAIContractForm.submitButtonText" />
                  : <LoadingDots className={css.loadingDots} />
                }
              </PrimaryButton>
            </div>
            <div className={css.chatButton}>
            </div>
          </div>
        </>
      )}
      <div className={css.sessionExpiryText}>
        {isSessionExpired ? (
          `This link has expired on ${linkExpiryDate}.`
        ) : (
          `This query link is valid till ${linkExpiryDate}.` 
        )}
        {moment() < moment(expiresAt) && expiryDatePlus15Days < createdAtDatePlus90Days && (
          <button onClick={async () => {
            await extendQueryLinkExpiry({sessionId: id, expiryDate: expiresAt})
            fetchData(id)
          }}>Extend</button>
        )}
        <span 
          className={css.tooltip}
          data-tooltip={
            expiryDatePlus15Days > createdAtDatePlus90Days 
              ? 'To extend validity further, contact InsightGig representative.' 
              : moment() < moment(expiresAt) ?  'Clicking this link will extend validity by 15 days.' : ''
          }
        > ? </span>
      </div>
      <Modal
        id='analyseRawQuantitativeDataDeleteModal'
        isOpen={deleteModal}
        onClose={() => {
          setDeleteModal(false)
        }}
        onManageDisableScrolling={manageDisableScrolling}
        removeCloseButton={true}
      >
        <div className={css.alertModal}>
          <h2>
            <FormattedMessage id="OpenAiAppsPanel.deleteMessages" />
          </h2>
          <div className={css.buttonWrapper}>
            <div className={css.cancelButton} onClick={(e) => {
              e.preventDefault()
              deleteModal!=='loading' && setDeleteModal(false)
            }}>
              Cancel
            </div>
            <PrimaryButton
            type='button'
            className={css.submitButton}
            onClick={deleteMessages}
            >
              {deleteModal==='loading' ? 
                <IconSpinner strokeColor='white' />
              :
                <FormattedMessage id='EditListingWorkExperienceFormComponent.deleteSchedule' />
              }
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <Modal
        id="GigAppQueryReportSection.sessionExpiredAlertModal"
        isOpen={isSessionExpired}
        onClose={() => history.push('/')}
        usePortal
        onManageDisableScrolling={manageDisableScrolling}
      >
        <div className={css.alertModal}>
          <h2><FormattedMessage id="QueryReportPage.sessionExpiredAlert" /></h2>
          <p>
            <FormattedMessage 
              id="QueryReportPage.sessionExpiredMessage" 
              values={{
                link: <NamedLink name='OpenAiAppsPage' params={{slug: 'ai-analyze-raw-quantitative-data'}}>here</NamedLink>
              }}
            />
          </p>
        </div>
      </Modal>
    </div>
  )
}

const QueryAnalyzeRawQuantitativeData = compose(
  withRouter,
)(QueryAnalyzeRawQuantitativeDataComponent);

export default QueryAnalyzeRawQuantitativeData;